<template>
  <div style="margin: 0 auto">
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">Codeliste</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button class="ozg-button" @click="openCrud"
          >Daten hinzufügen</b-button
        >

        <CodeListTable
          v-if="dataReceived"
          :fields="fields"
          :getReferences="getReferences"
          :addVersion="addVersion"
          :editCollectionItem="editCollectionItem"
          :deleteCollectionItem="deleteCollectionItem"
          :showDefinition="showDefinition"
          :showLoadingOverlay="showLoadingOverlay"
          :items="items"
        >
        </CodeListTable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueCsvImport } from "vue-csv-import";

import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
import { Components } from "formiojs";
export default {
  Components: [VueCsvImport],
  data() {
    return {
      csv: {},
      dataReceived: false,
      fields: [
        {
          key: "show_details",
          label: "",
          sortable: false,
          tdClass: "details-control",
        },
        {
          key: "codelistId",
          label: "#",
          sortable: false,
        },
        {
          key: "title",
          label: "Name",
          sortable: true,
        },
        {
          key: "identifier",
          label: "Aufruf-Key",
          sortable: true,
        },
        {
          key: "version",
          label: "Version",
          sortable: true,
        },

        {
          key: "isActive",
          label: "Aktiv?",
          sortable: true,
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: false,
        },

        {
          key: "uploadFileName",
          label: "Quell-Datei",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Erstellt",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Bearbeitet",
          sortable: true,
        },

        {
          key: "definition",
          label: "Definition/Datenfelder",
          sortable: false,
        },

        {
          key: "references",
          label: "Referenzen",
          sortable: true,
        },

        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],

      editFields: [
        {
          key: "title",
          title: "Collection-Name",
          type: "text",
        },
        {
          key: "description",
          title: "Beschreibung",
          type: "text",
        },
      ],
      items: [],
      currentCollection: 1,
      currentTableKey: 1,
      showLoadingOverlay: false,
    };
  },

  mounted() {
    this.currentTableKey = moment().format("X");
    this.initializeCollection();
  },

  methods: {
    getReferences(item) {
      if (item.codeListReference != null) {
        let references = {};
        if (typeof item.codeListReference === "string") {
          references = JSON.parse(item.codeListReference);
        } else {
          references = item.codeListReference;
        }

        if (references != null) {
          let length = references.length;
          let values = null;


          return length;
        }
      }
      return 0;
    },
    readFile() {
      let file = this.$refs.csv.files[0];

      if (file) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (evt) {
          const lines = evt.target.result.split("\n"); // 1️⃣
          const header = lines[0].split(";"); // 2️⃣
          const output = lines.slice(1).map((line) => {
            const fields = line.split(";"); // 3️⃣
            return Object.fromEntries(header.map((h, i) => [h, fields[i]])); // 4️⃣
          });
          callback(evt.target.result);
        };
        reader.onerror = function () {};
      } else {
      }
    },
    initializeCollection() {
      let self = this;

      axios.get(config.API_URL + "/api/codelist").then((response) => {
        self.items = response.data;
        self.dataReceived = true;

        //      self.renderDataTable();
      });
    },

    renderDataTable() {
      let self = this;
      $(document).ready(function () {
        setTimeout(function () {
          var table = $(".collectionTable").DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
            },
          });
        }, 300);
      });
    },

    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    showDefinition(item) {
      let self = this;
      self.showLoadingOverlay = true;
      axios
        .get(config.API_URL + "/api/codelist/" + item.codelistId)
        .then((response) => {
          self.$root.$children[0].showBaseModal = true;

          self.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
          self.$root.$children[0].baseDialogData = {};
          self.$root.$children[0].baseDialogData.canSave = false;
          self.$root.$children[0].baseDialogData.json = JSON.parse(
            response.data.definition
          );

          self.$root.$children[0].baseDialogData.Key = moment().format("X");
          self.showLoadingOverlay = false;
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseModalSize = "xl";

      this.$root.$children[0].baseDialogComponentName = "CodeListDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    addVersion(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseModalSize = "xl";

      this.$root.$children[0].baseDialogComponentName = "CodeListDialog";
      this.$root.$children[0].baseDialogData = {};

      let orgItem = JSON.parse(JSON.stringify(item));

      item.codelistId = null;
      item.parentId = orgItem.codelistId;
      item.children = null;
      item.codeListReference = null;
      delete item.codelistId;
      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editCollectionItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "CodeListDialog";
      this.$root.$children[0].baseDialogData = {};

      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteCollectionItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/codelist/" + item.codelistId)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeCollection();
          });
      }
    },
    saveData(item) {
      let self = this;
      if (
        item.codelistId != null &&
        item.codelistId != undefined &&
        item.codelistId != -1
      ) {
        axios
          .put(config.API_URL + "/api/codelist/" + item.codelistId, item, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.savingData = false;
            self.initializeCollection();
          });
      } else {
        axios
          .post(config.API_URL + "/api/codelist", item, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;

            self.savingData = false;
            self.initializeCollection();
          });
      }
    },
  },
};
</script>