<template>
  <div style="margin: 0 auto">


    <b-row>
      <b-col cols="12" class="text-right">
        <b-button class="ozg-button" @click="openCrud">Daten hinzufügen</b-button>
      </b-col>
      <b-col cols="12">
        <h2 id="navigation-title">Data-Collections</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-table :fields="fields" :items="items" :class="'collectionTable' + currentTableKey">
          <template v-slot:cell(definition)="{ item }">
            <b-button @click="showDefinition(item)" class="ozg-button small simple">
              Ansehen/Bearbeiten <small><b-icon-pencil></b-icon-pencil></small>
            </b-button>
          </template>

          <template v-slot:cell(editValues)="{ item }">
            <b-button :href="'/cms/collection/item/' + item.collectionId + ''" class="ozg-button small simple">
              Datenpflege <small><b-icon-pencil></b-icon-pencil></small>
            </b-button>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <div style="width: 100px">
              <b-icon-pen style="margin-right: 10px; cursor: pointer" font-scale="1" v-b-tooltip.hover title=" bearbeiten"
                @click="editCollectionItem(item)"></b-icon-pen>
              <b-icon-files font-scale="1" v-b-tooltip.hover style="margin-right: 10px;cursor: pointer" title=" kopieren"
                @click="copyCollection(item)">
              </b-icon-files>

              <b-icon-trash font-scale="1" v-b-tooltip.hover style="cursor: pointer;margin-right: 10px;" title=" löschen"
                @click="deleteCollectionItem(item)">
              </b-icon-trash>
              <b-icon-cloud-arrow-up font-scale="1" v-b-tooltip.hover style="cursor: pointer" title=" Deployment"
                @click="deploy(item)">
              </b-icon-cloud-arrow-up>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      fields: [
        {
          key: "collectionId",
          label: "#",
          sortable: false,
        },
        {
          key: "title",
          label: "Collection-Name",
          sortable: false,
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Erstellt",
          sortable: false,
        },
        {
          key: "updated_at",
          label: "Bearbeitet",
          sortable: false,
        },

        {
          key: "definition",
          label: "Definition/Datenfelder",
          sortable: false,
        },
        {
          key: "editValues",
          label: "Daten ",
          sortable: false,
        },

        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],

      editFields: [
        {
          key: "title",
          title: "Collection-Name",
          type: "text",
        },
        {
          key: "formFormId",
          title: "InputForm-Formio",
          type: "foreignCollection",
          foreignController: "collectionForForms/1",
          foreignValue: "collectionValueId",
          foreignText: "formName",
          max: 1

        },
        {
          key: "description",
          title: "Beschreibung",
          type: "text",
        },
      ],
      items: [],
      currentCollection: 1,
      currentTableKey: 1,
    };
  },

  mounted() {
    this.currentTableKey = moment().format("X");
    this.initializeCollection();
  },

  methods: {
    initializeCollection() {
      let self = this;
      axios.get(config.API_URL + "/api/collection").then((response) => {
        this.items = response.data;
        $(document).ready(function () {
          setTimeout(function () {
            $(".collectionTable" + self.currentTableKey).DataTable({
              retrieve: true,

              language: {
                url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
              },
            });
          }, 500);
        });
      });
    },

    showDefinition(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.json = JSON.parse(item.definition);
      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveDefinition(item, self.$root.$children[0].baseDialogData.json);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    saveDefinition(item, definition) {
      item.definition = JSON.stringify(definition);

      if (item.formFormId) {
        item.formFormId = item.formFormId.id;
      }
      axios
        .put(config.API_URL + "/api/collection/" + item.collectionId, item)
        .then((response) => {
          this.$root.$children[0].showBaseModal = false;
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.fields = this.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editCollectionItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};

      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.fields = this.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    copyCollection(item, callback) {
      var deleteItem = confirm("Wirklich kopieren?");
      let self = this;

      let copyItem = {};
      copyItem.value = item.value;
      copyItem.collectionId = item.collectionId;

      if (deleteItem) {
        axios
          .put(
            config.API_URL + "/api/collectionCopy/" + item.collectionId
          )
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            self.initializeCollection();
          });
      }
    },

    deleteCollectionItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/collection/" + item.collectionId)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeCollection();
          });
      }
    },


    deploy(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "Deploy";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = { collectionId: item.collectionId, title: item.title };

      this.$root.$children[0].baseDialogData.valueField = "collectionId";

      this.$root.$children[0].baseDialogData.textField = "title";
      this.$root.$children[0].baseDialogData.table = "collection";
      this.$root.$children[0].baseDialogData.crudTable = "collection";


      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    saveData(item) {
      let self = this;
      if (item.formFormId != null && item.formFormId != undefined) {
        item.formFormId = item.formFormId.id;
      }
      if (
        item.collectionId != null &&
        item.collectionId != undefined &&
        item.collectionId != -1
      ) {
        axios
          .put(config.API_URL + "/api/collection/" + item.collectionId, item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);
            self.initializeCollection();
          });
      } else {
        axios
          .post(config.API_URL + "/api/collection/", item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeCollection();
          });
      }
    },
  },
};
</script>