<template>
  <div style="margin: 0 auto" class="h-100">

    <b-row class="h-100">
      <b-col cols="3" class="h-100">
        <b-card header-tag="header">
          <ul class="folder">
            <li style="border-bottom;: 1px solid" class="mt-2 pt-2 actionItem" @click="openCrud()">
              <b-icon-folder-plus title="Ordner hinzufügen" class="mr-2 actionItem"></b-icon-folder-plus>
              Neues Template
            </li>
          </ul>

          <div class="search-wrapper">
            <b-form-input type="text" v-model="search" placeholder="Template suchen" />

          </div>
          <hr>
          <ul class="folder main-template-tree">

            <li v-for="item in filteredList">
              <b-row>
                <b-col cols="8">
                  <span @click="loadTemplate(item.templateId, true)" style="cursor: pointer;display:inline-block"
                    :title="item.title">
                    <b-icon-file-richtext-fill></b-icon-file-richtext-fill>
                    ({{ item.templateId
                    }}) {{ item.title | truncate(30) }}.vue
                  </span>
                </b-col>

                <b-col cols="4">
                  <b-icon-code-slash @click="loadTemplate(item.templateId, true)" style="cursor: pointer;"
                    title="Template Editieren" class="mr-2 ml-2 actionItem"></b-icon-code-slash>

                  <b-icon-pencil @click="editCollectionItem(item)" title="Element bearbeiten" style="cursor: pointer;"
                    class="mr-2 actionItem"></b-icon-pencil>




                  <b-icon-files @click="copyTemplate(item)" title="Template kopieren" style="cursor: pointer;"
                    class="mr-2 actionItem"></b-icon-files>

                  <b-icon-trash title="Template entfernen" class="acitonItem" style="cursor: pointer;"
                    @click="deleteCollectionItem(item)"></b-icon-trash>

                  <b-icon-cloud-arrow-up font-scale="1" v-b-tooltip.hover style="cursor: pointer" title=" Deployment"
                    @click="deploy(item)">
                  </b-icon-cloud-arrow-up>
                </b-col>
              </b-row>
            </li>

          </ul>

          <ul class="folder">
            <li style="border-top;: 1px solid" class="mt-2 pt-2 actionItem" @click="openCrud()">
              <b-icon-folder-plus title="Template hinzufügen" class="mr-2 actionItem"></b-icon-folder-plus>
              Neues Template
            </li>
          </ul>
        </b-card>
      </b-col>
      <b-col cols="9">


        <h3 v-if="templateItem != null"> {{ templateItem.title }}.vue <small class="small-grey">Creation: {{
          templateItem.created_at | formatDateTime
        }}</small> <small class="small-grey">Last Update: {{ templateItem.updated_at | formatDateTime }}</small> </h3>
        <b-button v-if="templateItem != null" @click="showRevision = !showRevision">Revision anzeigen</b-button>
        <div id="TemplateEditor" style="width:100%;height:900px;"></div>


        <b-button class="dus-system-button" v-if="templateItem != null" @click="saveTemplate()">Speichern</b-button>

      </b-col>

    </b-row>



    <b-modal v-model="showRevision" size="xl" modal-class="modal-fullscreen">

      <b-container fluid>

        <b-row>


          <b-col cols="2">
            <ul class="folder main-template-tree">

              <li v-for="(item, index) in revisions">
                <b-row>
                  <b-col cols="12">
                    <span @click="loadRevision(item, index)" style="cursor: pointer;display:inline-block"
                      :title="item.revisionId">
                      <b-icon-file-richtext-fill></b-icon-file-richtext-fill>
                      ({{ item.revisionId
                      }}) {{ item.created_at | formatDateTime }}.vue <br><small>({{ item.creationUser }})</small>
                    </span>
                  </b-col>


                </b-row>
              </li>

            </ul>

          </b-col>

          <b-col cols="10">
            <div v-if="revisions.length > 0">
              <label for="range-2" v-if="rangeValue != revisions.length - 1">Revision wählen : {{
                revisions[rangeValue].revisionId }} - {{
    revisions[rangeValue].created_at | formatDateTime }}</label>


            </div>
            <div style="display: flex">
              <div id="left-header" style="width: calc(50% - 14px)">
                <h3 class="text-center">Alter Wert</h3>
              </div>
              <div style="width: calc(50% - 14px)">
                <h3 class="text-center">Neuer Wert</h3>
              </div>
            </div>
            <div id="container" style="width:100%;height:1100px;"></div>

          </b-col>
        </b-row>

      </b-container>

    </b-modal>



  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
import * as monaco from 'monaco-editor';

export default {
  data() {
    return {
      templateItem: null,
      rangeValue: 0,
      editor: null,
      showRevision: false,
      search: '',
      fields: [
        {
          key: "templateId",
          label: "#",
          sortable: false,
        },
        {
          key: "title",
          label: "Template-Name",
          sortable: false,
        },

        {
          key: "collectionValueId",
          label: "Collection",
          sortable: false,
        },

        {
          key: "templateTypeId",
          label: "TemplateType",
          sortable: false,
        },
        {
          key: "pluginId",
          label: "Plugin",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Erstellt",
          sortable: false,
        },
        {
          key: "updated_at",
          label: "Bearbeitet",
          sortable: false,
        },

        {
          key: "definition",
          label: "Template",
          sortable: false,
        },

        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],
      editFields: [
        {
          key: "title",
          title: "Template-Name",
          type: "text",
        },

        {
          key: "templateTypeId",
          title: "Template-Type",
          type: "foreign",
          foreignController: "templateType",
          foreignValue: "templateTypeId",
          foreignText: "title",
        },

        {
          key: "mailSubject",
          title: "E-Mail Betreff",
          type: "text",
        },

        {
          key: "collectionValueId",
          title: "Formular",
          type: "foreign",
          foreignController: "form",
          foreignValue: "formId",
          foreignText: "formName",
        },
        {
          key: "pluginId",
          title: "Plugin",
          type: "foreign",
          foreignController: "plugin",
          foreignValue: "pluginId",
          foreignText: "title",
        },
      ],
      items: [],
      revisions: [],
      currentTableKey: 1,
    };
  },

  mounted() {
    this.currentTableKey = moment().format("X");

    this.$root.$children[0].isCrud = true;
    document.addEventListener("keydown", this.doSave);
    this.initializeCollection();
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
  },

  methods: {
    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      }
      e.preventDefault();
      this.saveTemplate()
    },
    loadTemplate(templateId, withCreate = false) {
      let self = this;
      axios
        .get(config.API_URL + "/api/template/" + templateId)
        .then((response) => {
          self.templateItem = response.data;

          if (JSON.parse(response.data.definition) != null) {
            self.code = JSON.parse(response.data.definition).html;
          }
          else {
            self.code = "<div></div>";
          }
          if (withCreate && !self.hasEditor) {




            window.TemplateEditor = monaco.editor.create(document.getElementById('TemplateEditor'), {
              value: this.code,
              language: 'html'
            });
            self.hasEditor = true


          }
          else {
            window.TemplateEditor.setValue(self.code)



            let query = {};
            query.query = "usf_RevisionForEntry('template'," + self.templateItem.templateId + ")"
            axios.post(config.API_URL + "/api/dynamicQuery", query).then((response) => {
              let collectionData = JSON.parse(response.data)

              self.revisions = collectionData;
              self.rangeValue = self.revisions.length - 1

            });
          }
        });
    },

    loadRevision(revision, index) {

      let value = JSON.parse(revision.old_value).html;
      let new_value = JSON.parse(revision.new_value).html;

      // window.TemplateEditor.setValue(value)

      if (this.editor) {
        this.editor.dispose();
      }

      this.editor = monaco.editor.createDiffEditor(document.getElementById('container'), {
        // Render the diff inline
        renderSideBySide: true
      });
      this.editor.setModel({
        original: monaco.editor.createModel(value, 'html'),
        modified: monaco.editor.createModel(new_value, 'html'),

      });

    },
    saveTemplate() {
      let self = this;
      this.templateItem.definition = JSON.stringify({ html: window.TemplateEditor.getValue() });
      axios
        .put(
          config.API_URL + "/api/template/" + this.templateItem.templateId,
          this.templateItem
        )
        .then((response) => {
          self.$root.$children[0].showBaseModal = false;
          self.$root.$children[0].showMessage(
            "Gespeichert",
            "Daten gespeichert",
            "success"
          );

          this.loadTemplate(this.templateItem.templateId);
        });
    },
    initializeCollection() {
      let self = this;
      axios.get(config.API_URL + "/api/dynamicQuery/Templates").then((response) => {
        this.items = response.data;
        $(document).ready(function () {
          setTimeout(function () {
            $(".collectionTable" + self.currentTableKey).DataTable({
              retrieve: true,

              language: {
                url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
              },
            });
          }, 300);
        });
      });
    },

    showDefinition(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.json = JSON.parse(item.definition);
      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveDefinition(item, self.$root.$children[0].baseDialogData.json);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    saveDefinition(item, definition) {
      item.definition = JSON.stringify(definition);

      axios
        .put(config.API_URL + "/api/collection/" + item.collectionId, item)
        .then((response) => {
          this.$root.$children[0].showBaseModal = false;
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.fields = self.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editCollectionItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};

      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.fields = self.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteCollectionItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/template/" + item.templateId)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeCollection();
          });
      }
    },
    copyTemplate(item) {
      let self = this;

      var deleteItem = confirm("Wirklich kopieren?");



      if (deleteItem) {
        axios
          .put(config.API_URL + "/api/templateCopy/" + item.templateId)
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            if (callback) {
              callback();
            }
          });
      }
    },
    deploy(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "Deploy";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.valueField = "templateId";

      this.$root.$children[0].baseDialogData.textField = "title";
      this.$root.$children[0].baseDialogData.table = "template";
      this.$root.$children[0].baseDialogData.crudTable = "template";
      this.$root.$children[0].baseDialogData.deploymentDone = function () {
        alert("Übertragung erfolgreich")

        self.initializeCollection();
      };

      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },
    saveData(item) {
      let self = this;


      if (item.collectionValueId != undefined && typeof item.collectionValueId === "object") {

        item.collectionValueId = item.collectionValueId.id;
      }
      if (
        item.templateId != null &&
        item.templateId != undefined &&
        item.templateId != -1
      ) {
        axios
          .put(config.API_URL + "/api/template/" + item.templateId, item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.initializeCollection();
          });
      } else {
        axios.post(config.API_URL + "/api/template/", item).then((response) => {
          self.$root.$children[0].showBaseModal = false;
          self.initializeCollection();
        });
      }
    },
  },

  computed: {
    filteredList() {
      return this.items.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
};
</script>

<style scoped>
.folder,
.folder li {
  margin-left: 0px;
  padding-left: 0px;
  list-style: none !important;
}

.main-template-tree {

  max-height: 750px;
  overflow: hidden;
  overflow-y: scroll;
}

.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
</style>

<style>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
</style>