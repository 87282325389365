export default {

    API_URL: process.env.NODE_ENV === 'production' ?
        window.location.protocol + '//' + window.location.host + '/api' : 'https://localhost:44355/',




    /*   API_URL: process.env.NODE_ENV === 'production' ?
       'http://ozgnqs01.itom.report/api/' : 'https://localhost:44355/',*/
    /*    API_URL: process.env.NODE_ENV === 'production' ?
       '0' : 'https://localhost:44355/',*/


    DEPLOY_URL: process.env.NODE_ENV === 'production' ?
        'https://www.ozg-digital.de/api/' : 'https://localhost:44355/',


    /* API_URL: process.env.NODE_ENV === 'production' ?
        'https://www.ozg-digital.de/api/ : 'https://localhost:44355/',*/
    VERSION: "2.0.1",
    APP_KEY: "c2790ece23bcb7656ffef842d9f51054"
}