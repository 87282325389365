<template>
    <div style="margin: 0 auto">


        <b-row>
            <b-col cols="12" class="text-right">
                <b-button class="ozg-button" @click="openCrud">Daten hinzufügen</b-button>
            </b-col>
            <b-col cols="12">
                <h2 id="navigation-title">Seitenstruktur</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col>

                <b-table :fields="fields" :items="items" :class="'collectionTable' + currentTableKey">
              

                    <template v-slot:cell(pages)="{ item }">
                        <b-button :href="'/cms/structure/item/' + item.structureId + ''" class="ozg-button small simple">
                            Seiten ansehen/bearbeiten <small><b-icon-pencil></b-icon-pencil></small>
                        </b-button>
                    </template>

                    <template v-slot:cell(actions)="{ item }">
                        <div style="width: 65px">
                            <b-icon-pen style="margin-right: 10px; cursor: pointer" font-scale="1" v-b-tooltip.hover
                                title=" bearbeiten" @click="editCollectionItem(item)"></b-icon-pen>
                       
                            <b-icon-trash font-scale="1" v-b-tooltip.hover style="cursor: pointer" title=" löschen"
                                @click="deleteCollectionItem(item)">
                            </b-icon-trash>

                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
    data() {
        return {
            fields: [
                {
                    key: "structureId",
                    label: "#",
                    sortable: false,
                },
                {
                    key: "name",
                    label: "Struktur-Name",
                    sortable: false,
                },
                {
                    key: "key",
                    label: "Struktur-Key",
                    sortable: false,
                },
          
                {
                    key: "created_at",
                    label: "Erstellt",
                    sortable: false,
                },
                {
                    key: "created_at",
                    label: "Erstellt",
                    sortable: false,
                },
                {
                    key: "updated_at",
                    label: "Bearbeitet",
                    sortable: false,
                },

                {
                    key: "pages",
                    label: "Seiten",
                    sortable: false,
                },


                {
                    key: "actions",
                    label: "Aktionen",
                    sortable: false,
                },
            ],

            editFields: [
                {
                    key: "name",
                    title: "Struktur-Name",
                    type: "text",
                },
                {
                    key: "key",
                    title: "Struktur-Key",
                    type: "text",
                },
               
            ],
            items: [],
            currentCollection: 1,
            currentTableKey: 1,
        };
    },

    mounted() {
        this.currentTableKey = moment().format("X");
        this.initializeCollection();
    },

    methods: {
        initializeCollection() {
            let self = this;
            axios.get(config.API_URL + "/api/structure").then((response) => {
                this.items = response.data;
                $(document).ready(function () {
                    setTimeout(function () {
                        $(".collectionTable" + self.currentTableKey).DataTable({
                            retrieve: true,

                            language: {
                                url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
                            },
                        });
                    }, 300);
                });
            });
        },

   
        openCrud() {
            let self = this;
            this.$root.$children[0].showBaseModal = true;

            this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
            this.$root.$children[0].baseDialogData = {};
            this.$root.$children[0].baseDialogData.item = {};
            this.$root.$children[0].baseDialogData.fields = this.editFields;

            this.$root.$children[0].baseDialogData.saveFunction = function () {
                self.saveData(self.$root.$children[0].baseDialogData.item);
            };
            this.$root.$children[0].baseDialogData.Key = moment().format("X");
        },

        editCollectionItem(item) {
            let self = this;
            this.$root.$children[0].showBaseModal = true;

            this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
            this.$root.$children[0].baseDialogData = {};

            this.$root.$children[0].baseDialogData.item = item;

            this.$root.$children[0].baseDialogData.fields = this.editFields;

            this.$root.$children[0].baseDialogData.saveFunction = function () {
                self.saveData(self.$root.$children[0].baseDialogData.item);
            };
            this.$root.$children[0].baseDialogData.Key = moment().format("X");
        },

        deleteCollectionItem(item) {
            var deleteItem = confirm("Wirklich löschen?");

            if (deleteItem) {
                axios
                    .delete(config.API_URL + "/api/structure/" + item.structureId)
                    .then((response) => {
                        this.$root.$children[0].showBaseModal = false;
                        this.initializeCollection();
                    });
            }
        },
        saveData(item) {
            let self = this;

            if (
                item.structureId != null &&
                item.structureId != undefined &&
                item.structureId != -1
            ) {
                axios
                    .put(config.API_URL + "/api/structure/" + item.structureId, item)
                    .then((response) => {
                        self.$root.$children[0].showBaseModal = false;
                        self.$root.$children[0].afterSaveFunctionAction(item);
                        self.initializeCollection();
                    });
            } else {
                axios
                    .post(config.API_URL + "/api/structure/", item)
                    .then((response) => {
                        self.$root.$children[0].showBaseModal = false;
                        self.$root.$children[0].afterSaveFunctionAction(item);

                        self.initializeCollection();
                    });
            }
        },
    },
};
</script>