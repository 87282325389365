<template>
  <b-row class="text-center justify-content-center h-75 align-items-center " >
    <b-col cols="6">
      <h1>
        Schade... leider konnte die angeforderte Seite nicht gefunden werden
      </h1>
    </b-col>

    <b-col cols="6">
     
    </b-col>
  </b-row>
</template>


<script>
export default {
  mounted()
  {
  }
}
</script>