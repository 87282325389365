<template>
    <div class="file_manager" :key="'file' + $root.$children[0].viewComponentKey">
        <h1>Seiten-Explorer</h1>
        <b-row>
            <b-col cols="4">
                <b-card header="Seitenbaum" header-tag="header">
                    <ul class="folder">
                        <PageElement v-for="page in pages" :page="page" :key="page.pageId" :selectedPage="selectedPage">
                        </PageElement>
                        <li style="border-top: 1px solid" class="mt-2 pt-2 actionItem" @click="newPage()">
                            <b-icon-folder-plus title="Ordner hinzufügen" class="mr-2 actionItem"></b-icon-folder-plus>
                            Neue Seute
                        </li>
                    </ul>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>
<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
    data: function () {
        return {
            pages: [],
            files: [],
            filesFiltered: [],
            selectedPage: {
                pageId: -1,

            },
        };
    },

    mounted() {
        this.init();
    },


    methods: {
        init() {
            let self = this;
            let structureId = this.$route.params.id
            let query = {};
            query.query = "usf_GetPagesInStructure("+structureId+")"
            axios.post(config.API_URL + "/api/dynamicQuery", query).then((response) => {
                let collectionData = JSON.parse(response.data)

                self.pages = self.folderTree(collectionData);
                if (
                    self.selectedPage == null ||
                    self.selectedPage == undefined ||
                    self.selectedPage.pageId == -1
                ) {
                    self.selectedPage = self.pages[0];
                }

            });
        },
        getIconByMime(mime) {
            let icon = "file-earmark-text";

            switch (mime) {
                case "application/msword":
                    icon = "file-earmark-word";
                    break;
                case "application/pdf":
                    icon = "file-earmark-pdf";
                    break;
                case "video/mp4":
                    icon = "file-earmark-play";
                    break;
            }

            return icon;
        },

        newPage() {
            let self = this;
            self.$root.$children[0].addNewMainPage();
        },
        /*    deleteFile(file) {
                let self = this;
                this.$root.$children[0].deleteCollectionItem(file, function () {
                    self.init();
                });
            },
    
            getFilePath(file) {
                if (file.value.filePath != undefined) {
                    return file.value.filePath;
                } else if (
                    file.value.file != undefined &&
                    file.value.file.filePath != undefined
                ) {
                    return file.value.file.filePath;
                }
                return "";
            },*/
        folderTree(arr) {
            let arrMap = new Map(arr.map((item) => [item.pageId, item]));

            let tree = [];

            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];

                if (item.parentId !== undefined) {
                    let parentItem = arrMap.get(item.parentId);

                    if (parentItem) {
                        let { children } = parentItem;

                        if (children) {
                            parentItem.children.push(item);
                        } else {
                            parentItem.children = [item];
                        }
                    }
                } else {
                    tree.push(item);
                }
            }
            return tree;
        },

        /*   loadFiles(folder) {
               let self = this;
               let filesToFilter = JSON.parse(JSON.stringify(self.files));
   
               self.filesFiltered = filesToFilter.filter(
                   (ftf) =>
                       ftf.value.folder != undefined && ftf.value.folder.id == folder.id
               );
               self.selectedFolder = folder;
           },
   
           addFile() {
               let self = this;
               var collectionItem = {
                   pluginData: {
                       setting: {
                           collectionId: 5,
                           folder: {
                               id: this.selectedFolder.id,
                               text: this.selectedFolder.value.folderName,
                           },
                       },
                   },
               };
               collectionItem.hidden = false;
               this.$root.$children[0].addSingleCollectionItem(
                   collectionItem,
                   function () {
                       self.$root.$children[0].viewComponentKey = moment().format("x");
                       self.init();
                   }
               );
           },
   
           editFile(item) {
               let self = this;
   
               item.value.collectionValueId = item.id;
               var collectionItem = { id: item.id, value: item.value };
   
               this.$root.$children[0].editSingleCollectionItem(
                   collectionItem,
                   function () {
                       self.$root.$children[0].viewComponentKey = moment().format("x");
                   }
               );
           },*/

        /* load(folder) {
             this.loadFiles(folder);
         },*/
    },
};
</script>
  
<style scoped>
.file_manager .file a:hover .hover,
.file_manager .file .file-name small {
    display: block;
}

.file_manager .file {
    padding: 0 !important;
}

.file_manager .file .icon {
    text-align: center;
}

.file_manager .file {
    position: relative;
    border-radius: 0.55rem;
    overflow: hidden;
}

.file_manager .file .image,
.file_manager .file .icon {
    max-height: 180px;
    overflow: hidden;
    background-size: cover;
    background-position: top;
}

.file_manager .file .hover {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    transition: all 0.2s ease-in-out;
}

.file_manager .file a:hover .hover {
    transition: all 0.2s ease-in-out;
}

.file_manager .file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%;
}

.file_manager .file .icon i {
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
    color: #777;
    line-height: 100px;
}

.file_manager .file .file-name {
    padding: 10px;
    border-top: 1px solid #f7f7f7;
}

.file_manager .file .file-name small .date {
    float: right;
}

.folder {
    padding: 20px;
    display: block;
    color: #777;
}

@media only screen and (max-width: 992px) {
    .file_manager .nav-tabs {
        padding-left: 0;
        padding-right: 0;
    }

    .file_manager .nav-tabs .nav-item {
        display: inline-block;
    }
}

.card {
    background: #fff;
    transition: 0.5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: 0.55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

a:hover {
    text-decoration: none;
}

.file_manager li,
.file_manager>ul,
.folder,
.folder li {
    list-style: none !important;
}
</style>