<template>
  <div class="wrapper "  v-bind:class="{
        container: $root.$children[0].isAdminstrationMode,
      }">
    <div id="gjs" :key="'builder' + $root.$children[0].viewComponentKey">
      <BaseElement
        v-for="(item, index) in components"
        :baseData="item"
        :key="item.name + $root.$children[0].viewComponentKey + index"
        :parent="components"
        @move-up="moveItemUp"
        @move-down="moveItemDown"
      >
      </BaseElement>
    </div>
    <AddSection
      :clickAction="AddBaseSection"
      v-if="$root.$children[0].isAdminstrationMode || !isPageBuilder"
    ></AddSection>
    <hr v-if="$root.$children[0].isAdminstrationMode" />
    <b-button
      variant="light"
      @click="saveToDB()"
      v-if="$root.$children[0].isAdminstrationMode && isPageBuilder"
      ><b-icon-save class="mr-2"></b-icon-save>Seite speichern</b-button
    >
  </div>
</template>

<script>
import config from "../config/config.js";
import axios from "axios";
import moment from "moment";

export default {
  props: {
    currentPageId: { type: Number },
    componentKey: { type: String, default: "N/A" },
    isPageBuilder: { type: Boolean, default: true },
    contentId: { type: Number },
    contentComponents: { type: Array, default: () => [] },
  },
  data: () => ({
    pageToLoad: null,
    components: [],
  }),

  mounted() {
    let self = this;

    if (this.isPageBuilder) {
      self.pageToLoad =
        this.$route.meta.pageId != null && this.$route.meta.pageId != undefined
          ? this.$route.meta.pageId
          : 4;

      axios
        .get(config.API_URL + "/api/loadContent/" + self.pageToLoad)
        .then((response) => {
          if (
            response.data != null &&
            response.data != "" &&
            response.data.components != "" &&
            response.data.components != undefined
          ) {
            self.components = JSON.parse(response.data.components);
            self.$root.$children[0].viewComponentKey = moment().format("X");
          } else {
            self.components = [];
          }
        });
    } else {
      if (typeof self.contentComponents !== "object") {
        self.components = JSON.parse(this.contentComponents);
      } else {
        self.components = this.contentComponents;
      }
      self.$root.$children[0].viewComponentKey = moment().format("X");
    }
  },
  methods: {
    moveItemUp(component, parent = null) {
      if (parent == null) {
        this.move(this.components, component, -1);
      } else {
        this.move(parent, component, -1);
      }
      this.$root.$children[0].viewComponentKey = moment().format("x");
    },
    moveItemDown(component, parent = null) {
      this.move(this.components, component, 1);

      this.$root.$children[0].viewComponentKey = moment().format("x");
    },
    AddBaseSection() {
      let section = new Element(
        -1,
        "ElementSection",
        "ElementSection",
        "",
        "",
        false
      );

      this.components.push(section);
            this.$root.$children[0].viewComponentKey = moment().format("x");

    },

    move(array, element, delta) {
      var index = array.indexOf(element);
      var newIndex = index + delta;
      if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
      var indexes = [index, newIndex].sort(); //Sort the indixes
      array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
    },
    findNested(obj, parent, i) {
      let found = false;
      if (obj.needsToBeDeleted) {
        found = true;
        if (parent.components != undefined) {
          parent.components.splice(i, 1);
        }
      } else {
        if (obj && obj.components && obj.components.length > 0) {
          for (let j = 0; j < obj.components.length; j++) {
            this.findNested(obj.components[j], obj, j);
          }
        }
        if (
          obj.ColOne != undefined &&
          obj.ColOne.components &&
          obj.ColOne.components.length > 0
        ) {
          for (let j = 0; j < obj.ColOne.components.length; j++) {
            this.findNested(obj.ColOne.components[j], obj.ColOne, j);
          }
        }
        if (
          obj.ColTwo != undefined &&
          obj.ColTwo.components &&
          obj.ColTwo.components.length > 0
        ) {
          for (let j = 0; j < obj.ColTwo.components.length; j++) {
            this.findNested(obj.ColTwo.components[j], obj.ColTwo, j);
          }
        }
        if (
          obj.ColThree != undefined &&
          obj.ColThree.components &&
          obj.ColThree.components.length > 0
        ) {
          for (let j = 0; j < obj.ColThree.components.length; j++) {
            this.findNested(obj.ColThree.components[j], obj.ColThree, j);
          }
        }
      }
      return found;
    },
    saveToDB() {
      let self = this;
      for (let i = 0; i < this.components.length; i++) {
        if (this.findNested(this.components[i], this.components, i)) {
          this.components.splice(i, 1);
        }
      }

      axios
        .post(
          config.API_URL + "/api/storeContent/" + self.pageToLoad,
          { components: JSON.stringify(self.components) },
          {}
        )
        .then((val) => {
          this.$bvToast.toast("Gespeichert", {
            title: "Gespeichert",
            variant: "success",
            solid: true,
          });
        });
    },
  },

  watch: {
    contentComponents: {
      deep: true,
      handler: function (newColData) {},
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin-top: 0px;
  padding-bottom: 81px;
}
</style>