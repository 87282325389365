<template>
    <div>
        {{ summaryData }}
    </div>
</template>

<script>

import config from "../config/config.js";
import axios from "axios";
import moment from "moment";

export default {

    data() {
        return {
            summaryData: {},

        };
    },

    mounted() {


    }
}
</script>