import Component from 'formiojs/components/_classes/component/Component';
import componentEditForm from 'formiojs/components/_classes/component/Component.form';
import Components from 'formiojs/components/Components';

export default class ResetButtonComponent extends Component {
    static schema(...extend) {
        return Component.schema({
            label: 'Reset-Button',
            key: 'resetButton',
            type: 'resetButton'
        }, ...extend);
    }

    static get builderInfo() {
        return {
            title: 'Reset-Button',
            group: 'basic',
            icon: 'user',
            documentation: 'http://help.form.io/userguide/#textfield',
            weight: 120,
            schema: ResetButtonComponent.schema()
        };
    }

    get defaultSchema() {
        return ResetButtonComponent.schema();
    }

    get inputInfo() {
        const info = super.elementInfo();
        info.type = 'input';
        info.attr.type = 'text';
        return info;
    }

    render(container) {
        return super.render(super.renderTemplate('button', {
            input: {
                type: 'button',
                attr: {
                    class: 'btn btn-md btn-success'
                },
                content: '<i class="fa fa-rotate-left"></i> Standard wiederherstellen'
            },
        }));
    }

    attach(element) {
        this.loadRefs(element, {
            button: 'single',
            buttonMessageContainer: 'single',
            buttonMessage: 'single'
        });

        const superAttach = super.attach(element);
        this.attachButton();
        return superAttach;
    }

    onClick(event) {

        if (confirm("Möchten Sie wirklich die Eingaben auf den Standard zurücksetzen") == true) {
            let query = {}
            query.query = "sp_ResetCustomizingForm  @communityId =" + window.GlobalVueInstance.$root.$children[0].userData.communityId + ", @collectionValueId =" + window.formId;

            query.type = "execute"
            window.GLOBALAXIOS
                .post(window.GLOBALCONFIG.API_URL + "/api/dynamicQueryP/", query)
                .then((response) => {
                    alert("Daten zurück gesetzt");
                    location.reload();
                });
        } else {

        }


    }

    attachButton() {
        this.addEventListener(this.refs.button, 'click', this.onClick.bind(this));
    }
}



// Register the component to the Formio.Components registry.
Components.addComponent('resetButton', ResetButtonComponent);