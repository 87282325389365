<template>
    <b-row>


        <b-col cols="2">
            <b-form-select v-model="selectedTable" :options="tablesForDeployment" text-field="label"
                value-field="selectionTable" @change="loadTableItems()"></b-form-select>

        </b-col>
        <b-col> Liste 


{{ tableItems }}

        </b-col>


    </b-row>
</template>

<script>

import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default
    {
        data() {
            return {
                selectedTable: null,
                tableItems: [],
                tablesForDeployment: [{
                    label: "Collections", selectionTable: "collection"
                },
                {
                    label: "Layout", selectionTable: "layout"
                },
                {
                    label: "Template", selectionTable: "template"
                }]
            }
        },

        methods:
        {


            loadTableItems() {
                let self = this;
                axios
                    .get(config.API_URL + "/api/" + self.selectedTable)
                    .then((response) => {
                        self.tableItems = response.data;
                    });
            }
        }
    }


</script>