<template>
  <div id="accordion" class="
      h-100
      d-flex
      flex-column
      justify-content-center
      p-4
      overflow-y-auto
      body-bg
    ">
    <div class="container-md shadow-sm p-0 mx-auto bg-white">
      <div class="p-5">
        <h1 class="h1 mb-4 text-uppercase font-medium">Login</h1>
        <form @submit.prevent>
          <div class="collapse show" id="divLogin" data-parent="#accordion">
            <div class="form-group required">
              <input type="text" name="userName" class="form-control" id="txtEmail" maxlength="150" tabindex="1"
                required="" isemail="" v-model="user.userName"
                title="Verwenden Sie bitte Ihr E-Mail-Login Konto, welches Sie bei der Registrierung angegeben haben." />
              <label class="floating-label"><span>E-Mail</span></label>


              <b-icon-question-circle id="tooltip-target-1" style="position: absolute;top:-10px;left: 70px">
                Hover Me
              </b-icon-question-circle>
              <b-tooltip target="tooltip-target-1" triggers="hover">
                Verwenden Sie bitte Ihr E-Mail-Login Konto, welches Sie bei der Registrierung angegeben haben.
              </b-tooltip>
            </div>
            <div class="form-group input-group required">
              <input v-bind:type="[showPassword ? 'text' : 'password']" class="form-control" style="border:none!important"
                id="txtPassword" maxlength="50" name="password" tabindex="2" required="" v-model="user.password"
                title="Verwenden Sie bitte Ihr Passwort, welches Sie bei der Registrierung angegeben haben." />
              <label class="floating-label"><span>Passwort</span></label>

              <b-icon-question-circle id="tooltip-target-2" style="position: absolute;top:-10px;left: 70px">
                Hover Me
              </b-icon-question-circle>
              <b-tooltip target="tooltip-target-2" triggers="hover">
                Verwenden Sie bitte Ihr Passwort, welches Sie bei der Registrierung angegeben haben.
              </b-tooltip>
              <div class="input-group-append">
                <button class="btn btn-icon" @click="showPassword = !showPassword" type="button">
                  <i class="material-icons">visibility</i>
                </button>
              </div>
            </div>
            <div class="form-check form-group">
              <input type="checkbox" class="form-check-input" id="chkRememberMe" />
              <label class="form-check-label" for="chkRememberMe" tabindex="3">
                Passwort merken
              </label>
            </div>

            <div v-if="hasError" id="pErrorSummary" class="alert alert-danger" role="alert">
              <span v-if="notActiveUser">
                Das Benutzerkonto wurde deaktiviert. Bitte wenden Sie sich an Ihren Administrator.
              </span>
              <span v-else>
                Etwas ist beim Login schief gegangen, bitte bitte überprüfen Sie Ihr E-Mail Login-Konto und Passwort.
              </span>
            </div>
            <div class="mt-3">
              <button type="submit" class="btn btn-primary" id="btnLogin" @click="login()" tabindex="6">
                LOGIN
              </button>
              <a href="/signup" class="btn btn-border ml-1" tabindex="7">Registrieren</a>
              <a href="javascript:void(0);" class="btn btn-border ml-1"
                @click="forgotPasswordModal = !forgotPasswordModal" tabindex="8">Passwort
                vergessen?</a>

              <a href="javascript:void(0);" class="btn btn-border ml-1" @click="helpModal = !helpModal" tabindex="8">Hilfe
                anfordern</a>
            </div>
          </div>
        </form>



        <b-modal hide-footer :size="$root.$children[0].baseModalSize" centered v-model="helpModal" title="BootstrapVue">
          <template #modal-header="{ close }">
            <h5 class="modal-title">Hilfe anfordern</h5>
            <b-icon-x font-scale="3" style="color: #ccc" @click="closeHelpDialog()" class="closeIcon"></b-icon-x>
          </template>

          <div class="text-center">
            <p>
              Probleme bei der Anmeldung oder Registrierung?</p>
            <p>Wir unterstützen Sie selbstverständlich:</p>
            <p>Unter der E-Mail Adresse : <a href="mailto:support@ozgservices.de">support@ozgservices.de</a></p>
            <p>Unter der Service Telefonnummer: <a href="tel:+49 (0)6196 95 44 - 56">+49 (0)6196 95 44 - 563</a></p>

          </div>


          <template #modal-footer="{ ok, cancel, hide }">
          </template>
        </b-modal>


        <b-modal :size="$root.$children[0].baseModalSize" centered v-model="forgotPasswordModal" title="BootstrapVue">
          <template #modal-header="{ close }">
            <h5 class="modal-title">Passwort zurücksetzen</h5>
            <b-icon-x font-scale="3" style="color: #ccc" @click="closeDialog()" class="closeIcon"></b-icon-x>
          </template>

          <div class="form-group required">
            <input type="text" class="form-control" v-model="user.userName" placeholder="E-Mail" required="" isemail=""
              id="txtForgotPassWord" />
            <label class="floating-label"><span>E-Mail</span></label>
            <small class="form-text text-muted">Wir werden Ihre Daten unter keinen Umständen an Dritte weiterreichen.</small>
          </div>


          <div class="alert alert-success  fmessage" v-if="!hasError && sendLink" role="alert" id="dvFsuccess">
            Wir haben Ihnen eine E-Mail an {{ user.userName }} gesendet mit weiteren Anweisungen
          </div>
          <div class="alert alert-danger  fmessage" v-if="hasError && sendLink" role="alert" id="dvFerror"> Es ist
            ein Problem aufgetreten, bitte versuchen Sie es erneut.</div>
          <template #modal-footer="{ ok, cancel, hide }">
            <button class="btn btn-primary" @click="SendResetRequest();">
              Senden
            </button> </template>
        </b-modal>


        <div class="mt-3">
          © 2019 - {{ currentYear }}
          <a href="https://ozgservices.de" target="_blank">www.ozgservices.de</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import config from "../config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data: function () {
    return {
      showPassword: false,
      hasError: false,
      notActiveUser: false,
      sendLink: false,
      helpModal: false,
      forgotPasswordModal: false,

      currentYear: moment().format("YYYY"),
      user: {
        userName: "",
        password: "",
        ApplicationKey: config.APP_KEY,
      },
    };
  },

  mounted() {
    let guuid = this.$route.params.guuid;
    axios
      .get(config.API_URL + "/api/attempt/" + guuid, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        this.attemptData = response.data;
        this.user.ApplicationKey = response.data.applicationKey;
      });
  },
  methods: {
    login() {
      let self = this;
      self.hasError = false;
      self.notActiveUser = false;
      axios
        .post(config.API_URL + "/api/Authenticate/", this.user, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if ((response.status == "201" || response.status == "200") && response.data != "USER_NOT_ACTIVE") {
            let userData = [];
            userData["token"] = response.data;

            window.location = "/?t=" + response.data;
            self.hasError = false;
            self.notActiveUser = false;

          } else if (response.data == "USER_NOT_ACTIVE") {
            self.hasError = true;
            self.notActiveUser = true;
          }

          else if (response.status == 500) {
            self.hasError = true;
          } else {
            self.hasError = true;
          }
        })
        .catch((error) => {

          if (error.response) {
            console.log("response")
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log("request")

            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            console.log("else")

            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
          self.hasError = true;
        });
    },

    closeDialog() {
      this.forgotPasswordModal = false;
    },

    closeHelpDialog() {
      this.helpModal = false;
    },


    SendResetRequest() {
      let self = this;
      axios
        .post(config.API_URL + "/api/ForgotPassword", { userMail: this.user.userName }, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          self.sendLink = true;
          if (response.status == "201" || response.status == "200") {

            self.hasError = false;
          } else if (response.status == 500) {
            self.hasError = true;
          } else {
            self.hasError = true;
          }
        })
        .catch((error) => {
          self.sendLink = true;

          self.hasError = true;
        });
    }
  },
};
</script>

<style></style>