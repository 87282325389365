<template>
    <div>

        <div class="mt-3 mb-4" style="width: 100%">
            <b-row>
                <b-col cols="12">
                    <h3>
                        BPMN Designer | {{ currentForm.formName }}

                    </h3>

                </b-col>


                <b-col cols="10" class="mb-3">
                    <b-btn @click="isViewer = !isViewer" class="mr-5">Editieren</b-btn>
                    <b-btn @click="saveForm(true)" variant="success" class="ml-5 mr-5" v-if="!isViewer">Speichern</b-btn>
                    <b-btn @click="isViewer = !isViewer" v-if="!isViewer">Abbrechen</b-btn>

                </b-col>
            </b-row>
            <div id="canvas"></div>
        </div>
    </div>
</template>
<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnViewer from 'bpmn-js/lib/Viewer';

export default {
    data() {
        return {
            currentForm: {},
            isViewer: true,
            bpm: null,
            newBPM: { xml: '<?xml version="1.0" encoding="UTF-8"?> <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn"> <bpmn2:process id="Process_1" isExecutable="false"> <bpmn2:startEvent id="StartEvent_1"/> </bpmn2:process> <bpmndi:BPMNDiagram id="BPMNDiagram_1"> <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"> <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1"> <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/> </bpmndi:BPMNShape> </bpmndi:BPMNPlane> </bpmndi:BPMNDiagram> </bpmn2:definitions>' }
        }
    },

    mounted() {
        let self = this;

        if (this.$route.params.id != undefined) {
            self.definitionName = "formBPM";
            axios
                .get(config.API_URL + "/api/CollectionItem/" + self.$route.params.id)
                .then((response) => {
                    self.collectionValue = response.data;
                    self.currentForm = JSON.parse(response.data.value);

                    document.title =
                        self.currentForm.formName + " | BPM  ";

                    if (
                        self.currentForm[self.definitionName] == null ||
                        self.currentForm[self.definitionName] == undefined
                    ) {
                        self.currentForm[self.definitionName] = self.newBPM
                    }


                    self.loadBPM();
                });
        }
    },
    methods: {
        loadBPM() {
            let self = this;


            $("#canvas").empty()
            if (this.isViewer) {
                this.bpm = new BpmnViewer({
                    container: '#canvas',
                    width: '100%',
                    height: '100%',
                    keyboard: {
                        bindTo: window
                    }
                });
            } else {
                this.bpm = new BpmnModeler({
                    container: '#canvas',
                    width: '100%',
                    height: '100%',
                    keyboard: {
                        bindTo: window
                    }
                });
            }
            self.bpm.clear();
            try {

                this.bpm.importXML(self.currentForm[self.definitionName].xml);

                // access modeler components
                var canvas = this.bpm.get('canvas');
                var overlays = this.bpm.get('overlays');


                // zoom to fit full viewport
                canvas.zoom('fit-viewport');

            } catch (err) {

                console.error('could not import BPMN 2.0 diagram', err);
            }
        },
        saveForm(showSaved = false) {
            let self = this;


            axios
                .get(config.API_URL + "/api/CollectionItem/" + self.$route.params.id)
                .then((response) => {

                    let liveValue = response.data;
                    let liveForm = JSON.parse(response.data.value);
                    self.bpm.saveXML().then(xml => {


                        liveForm[self.definitionName] = xml;
                        liveValue.value = JSON.stringify(liveForm);
                        axios
                            .put(
                                config.API_URL + "/api/CollectionItem/" + self.$route.params.id,
                                liveValue,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            )
                            .then((response) => {
                                self.bpm.importXML(xml.xml);
                                if (showSaved) {
                                    self.$root.$children[0].showMessage(
                                        "Gespeichert",
                                        "Daten gespeichert",
                                        "success"
                                    );
                                }
                            });
                    });
                });
        },
    }
    ,
    watch: {
        isViewer: {
            deep: true,
            handler: function (newColData) {
                let self = this;
                self.bpm.clear();
                axios
                    .get(config.API_URL + "/api/CollectionItem/" + this.$route.params.id)
                    .then((response) => {
                        self.collectionValue = response.data;
                        self.currentForm = JSON.parse(response.data.value);

                        document.title =
                            self.currentForm.formName + " | BPM  ";

                        if (
                            self.currentForm[self.definitionName] == null ||
                            self.currentForm[self.definitionName] == undefined
                        ) {
                            self.currentForm[self.definitionName] = self.newBPM
                        }


                        self.loadBPM();
                    });
            },
        },
    }
}

</script>
<style src="bpmn-js/dist/assets/bpmn-js.css"></style>
<style src="bpmn-js/dist/assets/diagram-js.css"></style>
<style src="bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css"></style>

<style scoped>
#canvas {
    height: 1000px;

    min-height: 1000px;
    padding: 0;
    margin: 0;
    border: 1px solid
}
</style>