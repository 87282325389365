import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VJsoneditor from 'v-jsoneditor/src/index'
import VueCodemirror from 'vue-codemirror';
import moment from 'moment-timezone'
import axios from 'axios';
import VueLocalforage from 'v-localforage'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue2Editor from "vue2-editor";
import VueMobileDetection from "vue-mobile-detection";
import { VueCsvImportPlugin } from 'vue-csv-import';
import Inputmask from "inputmask";

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
Vue.use(Inputmask)


import VueSession from 'vue-session'
Vue.use(VueSession)
Vue.use(VueCsvImportPlugin);

Vue.use(Vue2Editor);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VJsoneditor)
Vue.use(VueLocalforage)
Vue.use(VueMobileDetection);

axios.defaults.headers.common['Content-Type'] = "application/json";

Vue.use(VueCodemirror);
const ComponentContext = require.context('./components/', true, /\.vue$/i, 'lazy');

ComponentContext.keys().forEach((componentFilePath) => {

    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));

});


Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(value).format("DD.MM.YYYY");
    }
});

Vue.filter('formatDateShort', function(value) {
    if (value) {
        return moment(value).format("DD.MM");
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(value).format("DD.MM.YYYY HH:mm");
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(value).format("HH:mm");
    }
});


var filterTruncate = function(text = "", length = 200, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filterTruncate);
import Element from "./classes/Element";
window.Element = Element;
import Section from "./classes/Section";
window.Section = Section

import JQuery from 'jquery'
window.$ = JQuery

window.jQuery = JQuery


Vue.directive('input-mask', {


    bind: function(el) {
        $(el).inputmask({
            mask: $(this).attr("mask")
        });

    },
});
import DataTables from 'datatables.net-dt'
//import "datatables.net-select-dt"
//import "datatables.net-searchpanes-bs5"
//import "datatables.net-searchpanes-dt"
//import "datatables.net-colreorder-dt"

import "datatables.net-scroller-dt"
import "datatables.net-staterestore-dt"


Vue.config.productionTip = false
import config from "./config/config.js";
window._config = config


const compiler = require("vue-template-compiler");

window.compiler = compiler
window.GLOBALAXIOS = axios;
window.GLOBALCONFIG = config
window.VueGlobal = Vue;


window.GlobalVueInstance = new Vue({
    router,
    render: h => h(App)
}).$mount('#app')