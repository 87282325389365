<template>
  <div style="margin: 0 auto">
    <b-row>
      <b-col> <b-button @click="$router.go(-1)">Zurück</b-button> </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">Data-Object ({{ defItem.title }})</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button class="ozg-button" @click="openCrud"
              >Daten hinzufügen</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-table
              :fields="fields"
              :items="items"
              :class="'collectionTable' + currentTableKey"
            >
              <template v-slot:cell(actions)="{ item }">
                <div style="width: 65px">
                  <b-icon-pen
                    style="margin-right: 10px; cursor: pointer"
                    font-scale="1"
                    v-b-tooltip.hover
                    title=" bearbeiten"
                    @click="editDataObjectItem(item)"
                  ></b-icon-pen>
                  <b-icon-files
                    font-scale="1"
                    v-b-tooltip.hover
                    style="margin-right: 10px; cursor: pointer"
                    title=" kopieren"
                    @click="copyDataObjectItem(item)"
                  >
                  </b-icon-files>
                  <b-icon-trash
                    font-scale="1"
                    v-b-tooltip.hover
                    style="cursor: pointer"
                    title=" löschen"
                    @click="deleteDataObjectItem(item)"
                  >
                  </b-icon-trash>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      fields: [],
      items: [],
      currentDataObject: 1,
      defItem: {},
      currentTableKey: 1,
    };
  },

  mounted() {
    this.currentDataObject = this.$route.params.id;
    this.currentTableKey = moment().format("X");

    this.initializeDataObject();
  },

  methods: {
    initializeDataObject() {
      let self = this;
      axios
        .get(config.API_URL + "/api/DataObjectDef/" + self.currentDataObject)
        .then((responseValue) => {
          self.defItem = responseValue.data;
          axios
            .get(config.API_URL + "/api/dataObject/" + self.currentDataObject)
            .then((response) => {
              let list = [];

              if (JSON.parse(response.data)[0].items != null) {
                list = JSON.parse(response.data)[0].items.map((obj) => ({
                  dataObjectValueId: obj.dataObjectValueId,
                  value: JSON.parse(obj.value),
                }));
              }
              let fields = JSON.parse(
                JSON.parse(response.data)[0].fields
              ).fields;

              self.fields.push({
                key: "dataObjectValueId",
                title: "#",
                type: "actions",
              });
              fields.forEach(function (field) {
                field.key = "value." + field.key;
                field.label = field.title;
                self.fields.push(field);
              });
              self.fields.push({
                key: "actions",
                title: "Aktionen",
                type: "actions",
              });

              self.items = list;
              $(document).ready(function () {
                $(".collectionTable" + this.currentTableKey).DataTable({
                  language: {
                    url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
                  },
                });
              });
            });
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.foreignKeyValue =
        self.defItem.dataObjectId;
      this.$root.$children[0].baseDialogData.fields = self.defItem.definition;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };

      this.$root.$children[0].baseDialogData.item.OrgSaveFunction =
        this.$root.$children[0].baseDialogData.saveFunction;
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editDataObjectItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};

      let editItem = item.value;
      editItem.dataObjectValueId = item.dataObjectValueId;

      this.$root.$children[0].baseDialogData.item = editItem;
      this.$root.$children[0].baseDialogData.foreignKeyValue =
        self.defItem.dataObjectId;
      this.$root.$children[0].baseDialogData.fields = self.defItem.definition;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };

      editItem.OrgSaveFunction =
        this.$root.$children[0].baseDialogData.saveFunction;

      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteDataObjectItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(
            config.API_URL + "/api/dataObjectItem/" + item.dataObjectValueId
          )
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeDataObject();
          });
      }
    },
    saveData(item) {
      let self = this;
      item.dataObjectId = self.defItem.dataObjectId;
      item.value = JSON.stringify(item);

      if (
        item.dataObjectValueId != null &&
        item.dataObjectValueId != undefined &&
        item.dataObjectValueId != -1
      ) {
        axios
          .put(
            config.API_URL + "/api/dataObjectItem/" + item.dataObjectValueId,
            item
          )
          .then((response) => {
            item.dataObjectValueId = response.data.dataObjectValueId;
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeDataObject();
          });
      } else {
        axios
          .post(config.API_URL + "/api/dataObjectItem/", item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            item.dataObjectValueId = response.data.dataObjectValueId;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeDataObject();
          });
      }
    },
  },
};
</script>