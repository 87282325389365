export default class Element {
    name = "";
    identifier = "";
    data = null;
    template = null;
    components = [];
    isEditable = false;
    hasSettings = false;
    defaults = {}
    pluginObject = {};
    id = -1;

    constructor(id, name, identifier, data = null, template = "", isEditable = false, hasSettings = false, defaults = {}, pluginObject = {}, components = []) {
        this.name = name;
        this.identifier = identifier;
        this.id = id;
        this.components = components
        this.data = data;
        this.template = template;
        this.isEditable = isEditable;
        this.hasSettings = hasSettings;
        this.defaults = defaults;
        this.pluginObject = pluginObject
    }
}