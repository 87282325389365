<template>
  <div style="margin: 0 auto">
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">Data-Objects</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button class="ozg-button" @click="openCrud"
          >Daten hinzufügen</b-button
        >

        <b-table
          :fields="fields"
          :items="items"
          :class="'collectionTable' + currentTableKey"
        >
          <template v-slot:cell(definition)="{ item }">
            <b-button
              @click="showDefinition(item)"
              class="ozg-button small simple"
            >
              Ansehen/Bearbeiten <small><b-icon-pencil></b-icon-pencil></small>
            </b-button>
          </template>

          <template v-slot:cell(editValues)="{ item }">
            <b-button
              :href="'/cms/dataObjects/item/' + item.dataObjectId + ''"
              class="ozg-button small simple"
            >
              Datenpflege <small><b-icon-pencil></b-icon-pencil></small>
            </b-button>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <div style="width: 65px">
              <b-icon-pen
                style="margin-right: 10px; cursor: pointer"
                font-scale="1"
                v-b-tooltip.hover
                title=" bearbeiten"
                @click="editDataObjectItem(item)"
              ></b-icon-pen>
              <b-icon-files
                font-scale="1"
                v-b-tooltip.hover
                style="margin-right: 10px; cursor: pointer"
                title=" kopieren"
                @click="copyDataObjectItem(item)"
              >
              </b-icon-files>
              <b-icon-trash
                font-scale="1"
                v-b-tooltip.hover
                style="cursor: pointer"
                title=" löschen"
                @click="deleteDataObjectItem(item)"
              >
              </b-icon-trash>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      fields: [
        {
          key: "dataObjectId",
          label: "#",
          sortable: false,
        },
        {
          key: "title",
          label: "Object-Name",
          sortable: false,
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Erstellt",
          sortable: false,
        },
        {
          key: "updated_at",
          label: "Bearbeitet",
          sortable: false,
        },

        {
          key: "definition",
          label: "Definition/Datenfelder",
          sortable: false,
        },
        {
          key: "editValues",
          label: "Daten ",
          sortable: false,
        },

        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],

      editFields: [
        {
          key: "title",
          title: "DataObject-Name",
          type: "text",
        },
        {
          key: "description",
          title: "Beschreibung",
          type: "text",
        },
      ],
      items: [],
      currentObject: 1,
      currentTableKey: 1,
    };
  },

  mounted() {
    this.currentTableKey = moment().format("X");

    this.initializeDataObject();
  },

  methods: {
    initializeDataObject() {
      axios.get(config.API_URL + "/api/dataObject").then((response) => {
        this.items = response.data;
        $(document).ready(function () {
          $(".collectionTable" + this.currentTableKey).DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
            },
          });
        });
      });
    },

    showDefinition(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.json = JSON.parse(item.definition);
      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveDefinition(item, self.$root.$children[0].baseDialogData.json);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    saveDefinition(item, definition) {
      item.definition = JSON.stringify(definition);

      axios
        .put(config.API_URL + "/api/dataObject/" + item.dataObjectId, item)
        .then((response) => {
          this.$root.$children[0].showBaseModal = false;
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.fields = this.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editDataObjectItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};

      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.fields = this.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteDataObjectItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/dataObject/" + item.dataObjectId)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeDataObject();
          });
      }
    },
    saveData(item) {
      let self = this;

      if (
        item.collectionId != null &&
        item.collectionId != undefined &&
        item.collectionId != -1
      ) {
        axios
          .put(config.API_URL + "/api/dataObject/" + item.collectionId, item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);
            self.initializeDataObject();
          });
      } else {
        axios
          .post(config.API_URL + "/api/dataObject/", item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeDataObject();
          });
      }
    },
  },
};
</script>