import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Collections from '../views/AdminArea/Collections.vue'
import CodeLists from '../views/AdminArea/CodeLists.vue'

import CollectionCrud from '../views/AdminArea/CollectionCrud.vue'
import Templates from '../views/AdminArea/Templates.vue'
import TemplatesCrud from '../views/AdminArea/TemplatesCrud.vue'

import Layout from '../views/AdminArea/Layout.vue'
import LayoutCrud from '../views/AdminArea/LayoutCrud.vue'
import Plugins from '../views/AdminArea/Plugins.vue'
import DataObject from '../views/AdminArea/DataObject.vue'
import DataObjectCrud from '../views/AdminArea/DataObjectCrud.vue'
import PageNotFound from '../views/PageNotFound.vue'
import FileBrowser from '../views/AdminArea/FileBrowser.vue'
import Login from '../views/Login.vue'
import FormBuilder from '../views/AdminArea/FormBuilder.vue'
import BPMBuilder from '../views/AdminArea/BPMBuilder.vue'

import Customizing from '../views/Customizing.vue'
import Signup from '../views/Signup.vue'
import MailBuilder from '../views/AdminArea/MailBuilder.vue'
import Summary from '../views/Summary.vue'

import Profil from '../views/Profile.vue'

import Forgot from '../views/Forgot.vue'
import Structure from '../views/AdminArea/Structure.vue'
import StructureCrud from '../views/AdminArea/StructureCrud.vue'
import Deployment from '../views/AdminArea/Deployment.vue'
import OZGForms from '../views/AdminArea/OZGForms.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/cms/ozg-forms',
        name: 'OZGForms',
        component: OZGForms,
        meta: { routeType: "CMSAdmin", title: "OZGForms" },
    },

    {
        path: '/deployment',
        name: 'Deployment',
        component: Deployment
    },
    {
        path: '/cms/structure',
        name: 'Structure',
        meta: { routeType: "CMSAdmin", title: "Seitenstrukturen" },

        component: Structure
    },
    {
        path: '/cms/structure/item/:id',
        name: 'StructureCrud',
        meta: { routeType: "CMSAdmin", title: "Seitenstrukturen-Item" },

        component: StructureCrud
    },
    {
        path: '/login/:guuid?',
        name: 'Login',
        component: Login
    },
    {
        path: '/cms/collections',
        name: 'Collections',
        name: 'Collections',
        meta: { routeType: "CMSAdmin", title: "Data-Collections" },

        component: Collections
    },

    {
        path: '/cms/codeLists',
        name: 'CodeLists',
        meta: { routeType: "CMSAdmin", title: "Code-Lists" },

        component: CodeLists
    },

    {
        path: '/cms/dataObjects',
        name: 'DataObject',
        meta: { routeType: "CMSAdmin", title: "Data-Objects" },

        component: DataObject
    },

    {
        path: '/cms/dataObjects/item/:id',
        name: 'DataObjectCrud',
        meta: { routeType: "CMSAdmin", title: "Single-Data-Object" },

        component: DataObjectCrud

    },

    {
        path: '/cms/collection/item/:id',
        name: 'CollectionCrud',
        meta: { routeType: "CMSAdmin", title: "Data-Collection-Item" },

        component: CollectionCrud
    },


    {
        path: '/cms/templates',
        name: 'Templates',
        meta: { routeType: "CMSAdmin", title: "System-Templates" },

        component: Templates
    },


    {
        path: '/cms/template/item/:id',
        name: 'TemplatesCrud',
        meta: { routeType: "CMSAdmin", title: "System-Template" },

        component: TemplatesCrud
    },

    {
        path: '/cms/layouts',
        name: 'Layouts',
        meta: { routeType: "CMSAdmin", title: "System-Layouts" },

        component: Layout
    },


    {
        path: '/cms/layouts/item/:id',
        name: 'LayoutCrud',
        meta: { routeType: "CMSAdmin", title: "System-Layout" },
        component: LayoutCrud
    },


    {
        path: '/cms/plugins',
        name: 'Plugins',
        meta: { routeType: "CMSAdmin", title: "System-Plugin" },

        component: Plugins
    },

    {
        path: '/cms/files',
        name: 'FileBrowser',
        meta: { routeType: "CMSAdmin", title: "Datei-Explorer" },

        component: FileBrowser
    },


    {
        path: '/cms/formbuilder/item/:id/:identifier',
        name: 'FormBuilder',
        component: FormBuilder,
        meta: {
            routeType: "CMSAdmin",

            title: "FormBuilder",

        }
    },

    {
        path: '/cms/bpmBuilder/item/:id',
        name: 'BPMBuilder',
        component: BPMBuilder,
        meta: {
            routeType: "CMSAdmin",

            title: "BPMBuilder",

        }
    },



    {
        path: '/summary',
        name: 'summary',
        component: Summary,
        meta: {
            title: "Zusammenfassung",

        }
    },


    {
        path: '/cms/mailbuilder/item/:id/:identifier',
        name: 'MailBuilder',
        component: MailBuilder,
        meta: {
            routeType: "CMSAdmin",

            title: "MailBuilder",

        }
    },

    {
        path: '/customizing/:id/:identifier',
        name: 'Customizing',
        component: Customizing,
        meta: {
            title: "Customizing",

        }
    },

    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    },


    {
        path: '/forgot/:identifier',
        name: 'Forgot',
        component: Forgot
    },

    {
        path: '/profile/:userId?',
        name: 'Profil',
        component: Profil
    },






    { path: "*", component: PageNotFound }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


const DEFAULT_TITLE = 'OZG Manager';
router.afterEach((to, from) => {

    Vue.nextTick(() => {
        document.title = DEFAULT_TITLE + " | " + to.meta.title;
    });
});
export default router