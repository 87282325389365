<template>
  <div style="margin: 0 auto" @keydown.ctrl.83.prevent.stop="saveTemplate()">
    <b-row>
      <b-col> <b-button @click="$router.go(-1)">Zurück</b-button> </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">
          Layout-Editor (&quot;{{ templateItem.title }}&quot;)<br />
          <small> {{ templateItem.plugin }}</small>
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <h3>Layout-Code:</h3>
        <div v-if="revisions.length > 0">
          <label for="range-2">Revision wählen</label>
          <b-form-input id="range-2" v-model="rangeValue" type="range" min="0" :max="revisions.length" step="1"
            @change="loadRevisionLayout(revisions[rangeValue])"></b-form-input>
          <div class="mt-2">Datum: {{ revisions[rangeValue].created_at }} {{ rangeValue }}</div>
        </div>
        <div id="LayoutEditor" style="width:100%;height:900px;"></div>

      </b-col>

      <b-col cols="6">
        <h3>CSS:</h3>
        <div v-if="revisions.length > 0">
          <label for="range-2">Revision wählen</label>
          <b-form-input id="range-2" v-model="rangeValue" type="range" min="0" :max="revisions.length" step="1"
            @change="loadRevisionCSS(revisions[rangeValue])"></b-form-input>
          <div class="mt-2">Datum: {{ revisions[rangeValue].created_at }} {{ rangeValue }}</div>
        </div>
        <div id="CSSEditor" style="width:100%;height:900px;"></div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button class="dus-system-button" @click="saveTemplate()">Speichern</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
import * as monaco from 'monaco-editor';

const compiler = require("vue-template-compiler");
import Vue from "vue";

export default {

  data() {
    return {
      created: false,
      code: "",
      compiledTemplate: null,
      style: "",
      hasEditors: false,
      templateItem: {},
      options: {
        contextmenu: true
      }
      , revisions: [],
      rangeValue: 0
    };
  },
  mounted() {
    let self = this;

    this.$root.$children[0].isCrud = true;
    document.addEventListener("keydown", this.doSave);
    window.CSSEditor = null;
    window.LayoutEditor = null;
    $(document).ready(function () {
      self.loadTemplate(self.$route.params.id, true);

    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
  },
  methods: {
    onChange(value) {
    },

    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      }
      e.preventDefault();
      this.saveTemplate()
    },
    loadTemplate(templateId, withCreate = false) {
      let self = this;
      axios
        .get(config.API_URL + "/api/layout/" + templateId)
        .then((response) => {
          self.templateItem = response.data;
          self.code = JSON.parse(response.data.definition).html;
          self.style = JSON.parse(response.data.styles).css;



          if (withCreate && !self.hasEditors) {

            if (!window.CSSEditor) {
              window.CSSEditor = monaco.editor.create(document.getElementById('CSSEditor'), {
                value: self.style,
                language: 'css'
              });
            }

            if (!window.LayoutEditor) {
              window.LayoutEditor = monaco.editor.create(document.getElementById('LayoutEditor'), {
                value: self.code,
                language: 'html'
              });

            }
            self.hasEditors = true;


          }
          self.updateKey = moment().format("X")


          let query = {};
          query.query = "usf_RevisionForEntry('layout'," + templateId + ")"
          axios.post(config.API_URL + "/api/dynamicQuery", query).then((response) => {

            if (response.data != null && !Array.isArray(response.data)) {


              let collectionData = JSON.parse(response.data)

              self.revisions = collectionData;
              self.rangeValue = self.revisions.length - 1
            }

          });
          //  CodeMirror.setSize("100%", "100%");
        });
    },


    loadRevisionCSS(revision) {

      let value = JSON.parse(revision.old_value).html;

      window.CSSEditor.setValue(value)

    },

    loadRevisionLayout(revision) {

      let value = JSON.parse(revision.old_value).html;

      window.LayoutEditor.setValue(value)

    },

    saveTemplate() {
      let self = this;
      this.templateItem.definition = JSON.stringify({ html: window.LayoutEditor.getValue() });
      this.templateItem.styles = JSON.stringify({ css: window.CSSEditor.getValue() });



      axios
        .put(
          config.API_URL + "/api/layout/" + this.templateItem.layoutId,
          this.templateItem
        )
        .then((response) => {
          self.$root.$children[0].showMessage(
            "Gespeichert",
            "Daten gespeichert",
            "success"
          );

          this.$root.$children[0].showBaseModal = false;
          this.loadTemplate(this.$route.params.id);
        });
    },
  },
  computed: {
    cmOptions() {
      return {
        tabSize: 2,
        mode: "htmlmixed",
        lineNumbers: true,
        readOnly: false,
        search: { config: { top: true } },

      };
    },
    cmOptionsCSS() {
      return {
        tabSize: 2,
        mode: "css",
        lineNumbers: true,
        readOnly: false,
        search: { config: { top: true } },

      };
    },
  },
};
</script>