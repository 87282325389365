<template>
  <div v-bind:class="{ 'py-5': $root.$children[0].isAdminstrationMode }"
    :key="'news' + $root.$children[0].viewComponentKey" style="">
    <b-col cols="12">
      <div>
        <b-row>
          <b-col cols="12">
            <h2 id="navigation-title"></h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div id="renderer"></div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import config from "../config/config.js";
import axios from "axios";
import moment from "moment";
import { Formio } from "formiojs";

export default {
  data: function () {
    return {
      defItem: {},
    };
  },
  mounted() {
    let self = this;
    this.$session.start();
    let loginData = {
      userName: this.$session.id(),
      password: "",
      ApplicationKey: config.APP_KEY,
    };
    self._sessionId = loginData.userName
    window.sessionId = self._sessionId;
    axios
      .post(config.API_URL + "/api/authenticateBySession/", loginData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status == "201" || response.status == "200") {
          self.$session.set("token", response.data);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data}`;
          let formCommunityDTO = {
            formId: 52,
          };

          window.formCommunityDTO = formCommunityDTO;
          window.formId = 52;
          axios
            .get(config.API_URL + "/api/collectionItem/52")
            .then((response) => {
              if (
                typeof JSON.parse(response.data.value).formDefintion ===
                "string"
              ) {
                self.defItem = JSON.parse(
                  JSON.parse(response.data.value).formDefintion
                );
              } else {
                self.defItem = JSON.parse(response.data.value).formDefintion;
              }
              self.showForm = true;
              Formio.setBaseUrl(config.API_URL + "/api/formManager");
              Formio.setProjectUrl(config.API_URL + "/api/formManager");

              self.currentForm = Formio.createForm(
                document.getElementById("renderer"),
                self.defItem,
                {
                  hooks: {
                    beforeNext: (currentPage, submission, next) => {
                      window.draftSubmission = submission;

                      if (
                        document.getElementsByClassName("summaryContainer")
                          .length > 0
                      ) {
                        window.summary =
                          document.getElementsByClassName(
                            "summaryContainer"
                          )[0].innerHTML;
                      }
                      next();

                    }
                  },
                  language: "de",
                  i18n: {
                    de: {
                      error: "Bitte Fehler korrigieren",

                      next: "Weiter",

                      previous: "Zurück",
                      cancel: "Abbrechen",

                      required: "{{field}} ist ein Pflichtfeld",
                      submit: "Abschicken",
                    },
                  },
                }
              )
                .then(function (form) {
                  self.viewListComponentKey = moment().format("x");
                  window.form = form;
                  form.nosubmit = false;

                  form.on("nextPage", function (submission) {
                    form.saveDraft();
                    window.formDefinition = form.schema;
                    window.draftSubmission = submission;

                  });
                  form.nosubmit = true;
                  form.on("submit", function (submission) {
                    let formValue = {};
                    if (
                      document.getElementsByClassName("summaryContainer")
                        .length > 0
                    ) {
                      window.summary =
                        document.getElementsByClassName(
                          "summaryContainer"
                        )[0].innerHTML;
                    }



                    formValue.summary = window.summary
                    submission.summary = window.summary
                    formValue.value = JSON.stringify(submission);

                    axios
                      .post(config.API_URL + "/api/signup", formValue)
                      .then((response) => {
                        if (response != undefined) {
                          self.$root.$children[0].showMessage(
                            "Gespeichert",
                            "Daten gespeichert",
                            "success"
                          );

                          window.location = "/"
                        } else {
                          self.$root.$children[0].showMessage(
                            "Fehler",
                            "Es ist Fehler aufgetreten",
                            "danger"
                          );
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            });
        }
      });
  },
};
</script>
