<template>
  <div style="margin: 0 auto" @keydown.ctrl.83.prevent.stop="saveTemplate()">
    <b-row>
      <b-col cols="6"> <b-button @click="$router.push('/cms/templates')">Zurück</b-button> </b-col>
      <b-col cols="6" class="text-right">
        <b-button @click="showPreview = !showPreview"><span v-if="showPreview">Hide</span><span v-else>Show</span>
          Preview</b-button><br />

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">
          Template-Editor (&quot;{{ templateItem.title }}&quot;)<br />
          <small> {{ templateItem.plugin }}</small>
        </h2>
      </b-col>
    </b-row>
    <b-row>

      <b-col cols="6" v-if="showPreview">
        <Editor api-key="xe176gic6bb25852fcfpycrbm5k0kged3pl87h37aoukmc0a" v-model="code" :init="tinyOptions" />

      </b-col>

      <div cols="6" v-bind:class="{ 'col-6': showPreview, 'col-12': !showPreview }">
        <div id="TemplateEditor" style="width:100%;height:700px;"></div>
      </div>


    </b-row>




    <b-row>
      <b-col>
        <b-button class="dus-system-button" @click="saveTemplate()">Speichern</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script setup>
import Editor from '@tinymce/tinymce-vue'
</script>
<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;
import * as monaco from 'monaco-editor';
const compiler = require("vue-template-compiler");
import Vue from "vue";

export default {
  data() {
    return {
      created: false,
      code: "<div></div>",
      showPreview: false,
      compiledTemplate: null,
      fields: {},
      tempFields: [],
      hasEditor: false,
      templateItem: {},
      options: {},
      tinyOptions: {
        selector: 'textarea#open-source-plugins',
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        toolbar_sticky_offset: isSmallScreen ? 102 : 108,
        /*   templates: [
             { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
             { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
             { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
           ],*/
        template_cdate_format: '[Date Created (CDATE): %d.%m/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE):  %d.%m/%Y : %H:%M:%S]',
        height: 650,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image table',
        skin: useDarkMode ? 'oxide-dark' : 'oxide',
        content_css: useDarkMode ? 'dark' : 'default',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
      }
    };
  },
  mounted() {
    this.$root.$children[0].isCrud = true;
    document.addEventListener("keydown", this.doSave);
    if (!self.hasEditor) {
      this.loadTemplate(this.$route.params.id, true);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
  },
  methods: {
    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      }
      e.preventDefault();
      this.saveTemplate()
    },
    loadTemplate(templateId, withCreate = false) {
      let self = this;
      axios
        .get(config.API_URL + "/api/template/" + templateId)
        .then((response) => {
          self.templateItem = response.data;
          if (JSON.parse(response.data.definition) != null) {
            self.code = JSON.parse(response.data.definition).html;
          }
          if (withCreate && !self.hasEditor) {




            window.TemplateEditor = monaco.editor.create(document.getElementById('TemplateEditor'), {
              value: this.code,
              language: 'html'
            });
            self.hasEditor = true;
          }
        });
    },

    onError(e) {
      console.log(e);
    },
    saveTemplate() {
      let self = this;
      this.templateItem.definition = JSON.stringify({ html: window.TemplateEditor.getValue() });
      axios
        .put(
          config.API_URL + "/api/template/" + this.templateItem.templateId,
          this.templateItem
        )
        .then((response) => {
          self.$root.$children[0].showBaseModal = false;
          self.$root.$children[0].showMessage(
            "Gespeichert",
            "Daten gespeichert",
            "success"
          );

          this.loadTemplate(this.$route.params.id);
        });
    },
  },

};
</script>