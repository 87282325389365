<template>
  <div style="margin: 0 auto">
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">Layouts</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button class="ozg-button" @click="openCrud"
              >Daten hinzufügen</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-table :fields="fields" :items="items"  :class="'collectionTable'+currentTableKey">
              <template v-slot:cell(definition)="{ item }">
                <b-button
                  :href="'/cms/layouts/item/' + item.layoutId + ''"
                  class="ozg-button small simple"
                >
                  Bearbeiten/Erstellen
                  <small><b-icon-pencil></b-icon-pencil></small>
                </b-button>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <div style="width: 65px">
                  <b-icon-pen
                    style="margin-right: 10px; cursor: pointer"
                    font-scale="1"
                    v-b-tooltip.hover
                    title=" bearbeiten"
                    @click="editCollectionItem(item)"
                  ></b-icon-pen>
                  
                
                  <b-icon-trash
                    font-scale="1"
                    v-b-tooltip.hover
                    style="cursor: pointer"
                    title=" löschen"
                    @click="deleteCollectionItem(item)"
                  >
                  </b-icon-trash>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      fields: [
        {
          key: "layoutId",
          label: "#",
          sortable: false,
        },
        {
          key: "title",
          label: "Layout-Name",
          sortable: false,
        },
        {
          key: "isStandard",
          label: "IsStandard",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Erstellt",
          sortable: false,
        },
        {
          key: "updated_at",
          label: "Bearbeitet",
          sortable: false,
        },

        {
          key: "definition",
          label: "Template",
          sortable: false,
        },

        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],
      editFields: [
        {
          key: "title",
          title: "Title-Name",
          type: "text",
        },
        {
          key: "description",
          title: "Beschreibung",
          type: "text",
        },

        {
          key: "isStandard",
          title: "isStandard",
          type: "switch",
        },
      ],
      items: [],
            currentTableKey:1,

    };
  },

  mounted() {
        this.currentTableKey = moment().format("X");

    this.initializeCollection();
  },

  methods: {
    initializeCollection() {
      axios.get(config.API_URL + "/api/layout").then((response) => {
        this.items = response.data;
        $(document).ready(function () {
          $(".collectionTable"+this.currentTableKey).DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
            },
          });
        });
      });
    },

    showDefinition(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.json = JSON.parse(item.definition);
      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveDefinition(item, self.$root.$children[0].baseDialogData.json);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.fields = self.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editCollectionItem(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};

      this.$root.$children[0].baseDialogData.item = item;

      this.$root.$children[0].baseDialogData.fields = self.editFields;

      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteCollectionItem(item) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/layout/" + item.templateId)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeCollection();
          });
      }
    },

    saveData(item) {
      let self = this;

      if (
        item.templateId != null &&
        item.templateId != undefined &&
        item.templateId != -1
      ) {
        axios
          .put(config.API_URL + "/api/layout/" + item.templateId, item)
          .then((response) => {
            this.$root.$children[0].showBaseModal = false;
            this.initializeCollection();
          });
      } else {
        axios.post(config.API_URL + "/api/layout/", item).then((response) => {
          this.$root.$children[0].showBaseModal = false;
          this.initializeCollection();
        });
      }
    },
  },
};
</script>