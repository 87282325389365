<template>
  <div class="file_manager" :key="'file' + $root.$children[0].viewComponentKey">
    <h1>Datei-Explorer</h1>
    <b-row>
      <b-col cols="4">
        <b-card header="Ordner" header-tag="header">
          <ul class="folder">
            <FolderElement
              v-for="folder in folders"
              :files="files"
              :folder="folder"
              :key="folder.id"
              :selectedFolder="selectedFolder"
              :loadFiles="loadFiles"
            ></FolderElement>
            <li
              style="border-top: 1px solid"
              class="mt-2 pt-2 actionItem"
              @click="newFolder()"
            >
              <b-icon-folder-plus
                title="Ordner hinzufügen"
                class="mr-2 actionItem"
              ></b-icon-folder-plus>
              Neuer Ordner
            </li>
          </ul>
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card :header="headerFolder" header-tag="header">
          <template #header>
            <b-row>
              <b-col cols="10">
                <h6 class="mb-0">{{ headerFolder }}</h6>
              </b-col>
              <b-col cols="2">
                <b-button
                  variant="light"
                  title="Datei hochladen"
                  @click.stop="addFile()"
                  ><b-icon-upload></b-icon-upload
                ></b-button>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <div
              class="col-lg-4 col-sm-12"
              v-for="(file, index) in filesFiltered"
              :key="index"
            >
              <div class="card">
                <div class="file">
                  <b-button icon variant="light">
                    <b-icon-pencil @click.stop="editFile(file)"></b-icon-pencil>
                  </b-button>
                  <b-button icon variant="danger">
                    <b-icon-trash @click.stop="deleteFile(file)"></b-icon-trash>
                  </b-button>

                  <div class="icon">
                    <a
                      :href="$root.$children[0].replacePath(getFilePath(file))"
                      target="_blank"
                    >
                      <span
                        v-if="
                          file.value != null &&
                          file.value.mime != null &&
                          file.value.mime.includes('image') &&
                          getFilePath(file) != ''
                        "
                      >
                        <b-img
                          :src="
                            $root.$children[0].replacePath(getFilePath(file))
                          "
                          fluid
                          style="height: 100px"
                        ></b-img>
                      </span>
                      <span v-else>
                        <b-icon
                          :icon="getIconByMime(file.value.mime)"
                          font-scale="4"
                        ></b-icon>
                      </span>
                    </a>
                  </div>
                  <div class="file-name">
                    <p class="m-b-5 text-muted">{{ file.value.fileName }}</p>
                    <small>
                      <span class="date text-muted" v-if="file.creationDate">{{
                        file.creationDate | formatDate
                      }}</span></small
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data: function () {
    return {
      folders: [],
      files: [],
      filesFiltered: [],
      selectedFolder: {
        id: -1,
        value: null,
      },
    };
  },

  mounted() {
    this.init();
  },

  computed: {
    headerFolder() {
     
      return this.selectedFolder != null
        ? (this.selectedFolder.value.parentFolder != undefined
            ? this.selectedFolder.value.parentFolder.text + " > "
            : "") + this.selectedFolder.value.folderName
        : "Ordner wählen";
    },
  },

  methods: {
    init() {
      let self = this;
      axios.get(config.API_URL + "/api/collection/6").then((response) => {
        if (
          JSON.parse(response.data)[0].items != null &&
          JSON.parse(response.data)[0].items != undefined
        ) {
          let list = JSON.parse(response.data)[0].items.map((i) => ({
            id: i.collectionValueId,
            value: JSON.parse(i.value),
          }));

          list = list.sort(
            (a, b) =>
              moment(b.value.title).format("x") -
              moment(a.value.title).format("x")
          );

          self.folders = this.folderTree(list);

          if (
            self.selectedFolder == null ||
            self.selectedFolder == undefined ||
            self.selectedFolder.id == -1
          ) {
            self.selectedFolder = self.folders[0];
          }
          axios.get(config.API_URL + "/api/collection/5").then((response) => {
            if (
              JSON.parse(response.data)[0].items != null &&
              JSON.parse(response.data)[0].items != undefined
            ) {
              let list = JSON.parse(response.data)[0].items.map((i) => ({
                id: i.collectionValueId,
                value: JSON.parse(i.value),
                creationDate: i.created_at,
              }));

              list = list.sort(
                (a, b) =>
                  moment(b.value.title).format("x") -
                  moment(a.value.title).format("x")
              );

              self.files = list;

              if (
                self.selectedFolder &&
                self.selectedFolder != null &&
                self.selectedFolder != undefined &&
                self.selectedFolder.id != -1
              ) {
                self.loadFiles(self.selectedFolder);
              }
            }
          });
        }
      });
    },
    getIconByMime(mime) {
      let icon = "file-earmark-text";

      switch (mime) {
        case "application/msword":
          icon = "file-earmark-word";
          break;
        case "application/pdf":
          icon = "file-earmark-pdf";
          break;
        case "video/mp4":
          icon = "file-earmark-play";
          break;
      }

      return icon;
    },

    newFolder() {
      let self = this;
      var collectionItem = {
        pluginData: {
          setting: {
            collectionId: 6,
          },
        },
      };
      this.$root.$children[0].addSingleCollectionItem(
        collectionItem,
        function () {
          self.$root.$children[0].viewComponentKey = moment().format("x");
          self.init();
        }
      );
    },
    deleteFile(file) {
      let self = this;
      this.$root.$children[0].deleteCollectionItem(file, function () {
        self.init();
      });
    },

    getFilePath(file) {
      if (file.value.filePath != undefined) {
        return file.value.filePath;
      } else if (
        file.value.file != undefined &&
        file.value.file.filePath != undefined
      ) {
        return file.value.file.filePath;
      }
      return "";
    },
    folderTree(arr) {
      let arrMap = new Map(arr.map((item) => [item.id, item.value]));

      let tree = [];

      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];

        if (item.value.parentFolder !== undefined) {
          let parentItem = arrMap.get(item.value.parentFolder.id);

          if (parentItem) {
            let { children } = parentItem;

            if (children) {
              parentItem.children.push(item);
            } else {
              parentItem.children = [item];
            }
          }
        } else {
          tree.push(item);
        }
      }
      return tree;
    },

    loadFiles(folder) {
      let self = this;
      let filesToFilter = JSON.parse(JSON.stringify(self.files));

      self.filesFiltered = filesToFilter.filter(
        (ftf) =>
          ftf.value.folder != undefined && ftf.value.folder.id == folder.id
      );
      self.selectedFolder = folder;
    },

    addFile() {
      let self = this;
      var collectionItem = {
        pluginData: {
          setting: {
            collectionId: 5,
            folder: {
              id: this.selectedFolder.id,
              text: this.selectedFolder.value.folderName,
            },
          },
        },
      };
      collectionItem.hidden = false;
      this.$root.$children[0].addSingleCollectionItem(
        collectionItem,
        function () {
          self.$root.$children[0].viewComponentKey = moment().format("x");
          self.init();
        }
      );
    },

    editFile(item) {
      let self = this;

      item.value.collectionValueId = item.id;
      var collectionItem = { id: item.id, value: item.value };

      this.$root.$children[0].editSingleCollectionItem(
        collectionItem,
        function () {
          self.$root.$children[0].viewComponentKey = moment().format("x");
        }
      );
    },

    load(folder) {
      this.loadFiles(folder);
    },
  },
};
</script>

<style scoped>
.file_manager .file a:hover .hover,
.file_manager .file .file-name small {
  display: block;
}
.file_manager .file {
  padding: 0 !important;
}

.file_manager .file .icon {
  text-align: center;
}

.file_manager .file {
  position: relative;
  border-radius: 0.55rem;
  overflow: hidden;
}

.file_manager .file .image,
.file_manager .file .icon {
  max-height: 180px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
}

.file_manager .file .hover {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  transition: all 0.2s ease-in-out;
}

.file_manager .file a:hover .hover {
  transition: all 0.2s ease-in-out;
}

.file_manager .file .icon {
  padding: 15px 10px;
  display: table;
  width: 100%;
}

.file_manager .file .icon i {
  display: table-cell;
  font-size: 30px;
  vertical-align: middle;
  color: #777;
  line-height: 100px;
}

.file_manager .file .file-name {
  padding: 10px;
  border-top: 1px solid #f7f7f7;
}

.file_manager .file .file-name small .date {
  float: right;
}

.folder {
  padding: 20px;
  display: block;
  color: #777;
}

@media only screen and (max-width: 992px) {
  .file_manager .nav-tabs {
    padding-left: 0;
    padding-right: 0;
  }
  .file_manager .nav-tabs .nav-item {
    display: inline-block;
  }
}

.card {
  background: #fff;
  transition: 0.5s;
  border: 0;
  margin-bottom: 30px;
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

a:hover {
  text-decoration: none;
}

.file_manager li,
.file_manager > ul,
.folder,
.folder li {
  list-style: none !important;
}
</style>