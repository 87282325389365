<template>
  <div class="home">
    <PageBuilder :currentPageId="$root.$children[0].currentPageId" :key="'builder' + $root.$children[0].viewComponentKey"> </PageBuilder>
  </div>
</template>

<script>


export default {
  data: () => ({
    pageToLoad: null,
  }),

  mounted() {
  },
};
</script>
