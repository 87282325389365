<template>
  <div>
    <b-row>
      <b-col cols="6"> <b-button @click="$router.push('/cms/collections')">Zurück</b-button> </b-col>
      <b-col cols="6" class="text-right">
        <b-button class="ozg-button" @click="openCrud">Daten hinzufügen</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h2 id="navigation-title">OZG-FORMS</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>

          <b-col cols="12">
            <b-table :fields="fields" :items="items" :class="'collectionTable' + currentTableKey" style="max-width: 100%;">
              <template #cell()="data">

                <span v-if="data.field.type == 'Formio'">
                  <b-button :href="'/cms/formBuilder/item/' +
                    data.item.formId +
                    '/' +
                    data.field.key.replace('value.', '')
                    " class="ozg-button small simple" target="_blank">
                    Konfigurieren
                    <small><b-icon-pencil></b-icon-pencil></small>
                  </b-button>
                </span>

                <span v-else-if="data.field.type == 'BPM'">
                  <b-button :href="'/cms/bpmBuilder/item/' +
                    data.item.formId
                    " class="ozg-button small simple" target="_blank">
                    BPMN
                    <small><b-icon-pencil></b-icon-pencil></small>
                  </b-button>
                </span>

                <span v-else-if="data.field.type == 'revisionCount'">
                  <b-button variant="primary" @click="showRevisions(data.item)">

                    {{ data.item.revisionCount }}

                  </b-button>
                </span>
                <span v-else-if="data.field.type == 'MailEditor'">
                  <b-button :href="'/cms/mailBuilder/item/' +
                    data.item.formId +
                    '/' +
                    data.field.key.replace('value.', '')
                    " class="ozg-button small simple" target="_blank">
                    Konfigurieren
                    <small><b-icon-pencil></b-icon-pencil></small>
                  </b-button>
                </span>
                <span v-else>
                  <span v-if="data.field.type != undefined &&
                    data.field.type.includes('foreign')
                    ">

                    <span v-if="data != null && data != undefined && data.field.type == 'foreignMultiSelect' &&
                      data.value != null &&
                      data.value != undefined &&
                      data.value != ''
                      ">
                      {{
                        data.value
                          .map((e) => e[data.field.foreignText])
                          .join(", ")
                      }}
                    </span>

                    <span v-if="data != null && data != undefined && data != '' &&
                        data.field.type == 'foreignMultiSelectWithMax' &&
                        data.value != null &&
                        data.value != undefined &&
                        data.value != ''
                        ">
                      {{
                        data.value
                          .map((e) => e[data.field.foreignText])
                          .join(", ")
                      }}
                    </span>


                    <span v-if="data.field.type == 'foreignSingleSelect'">
                      {{
                        data.item.value[data.field.foreignValue][
                        data.field.foreignText
                        ]
                      }}
                    </span>


                    <span v-else>
                      {{ data.value.text }}
                    </span>
                  </span>



                  <span v-else-if="data.field.key == 'created_at' || data.field.key == 'updated_at'">


                    {{
                      data.item[data.field.key] | formatDateTime
                    }}
                  </span>
                  <span v-else-if="data.field.key == 'ChangeUser'">


                    {{ data.item.ChangeUser }}
                  </span>
                  <span v-else>


                    {{ data.value }}
                  </span>
                </span>
              </template>
              <template v-slot:cell(actions)="{ item }">
                <div style="width: 65px">
                  <b-icon-pen style="margin-right: 10px; cursor: pointer" font-scale="1" v-b-tooltip.hover
                    title=" bearbeiten" @click="editCollectionItem(item)"></b-icon-pen>

                  <b-icon-files font-scale="1" v-b-tooltip.hover style="margin-right: 10px; cursor: pointer"
                    title=" kopieren" @click="copyCollectionItem(item)">
                  </b-icon-files>
                  <b-icon-trash font-scale="1" v-b-tooltip.hover style="cursor: pointer" title=" löschen"
                    @click="deleteCollectionItem(item)">
                  </b-icon-trash>
                  <b-icon-cloud-arrow-up font-scale="1" v-b-tooltip.hover style="cursor: pointer" title=" Deployment"
                    @click="deploy(item)">
                  </b-icon-cloud-arrow-up>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      fields: [],
      items: [],
      currentCollection: 1,
      defItem: {},
      currentTableKey: 1,
    };
  },

  mounted() {

    this.currentTableKey = moment().format("X");

    this.initializeCollection();
  },

  methods: {
    initializeCollection() {
      let self = this;
      axios
        .get(config.API_URL + "/api/CollectionDef/1")
        .then((responseValue) => {
          self.defItem = responseValue.data;
          let query = {}
          query.query = "usf_AllForms(" + window.GlobalVueInstance.$root.$children[0].userData.Id + ")"

          axios
            .post(config.API_URL + "/api/dynamicQuery/", query)
            .then((response) => {
              let list = [];

              if (response.data != null) {
                list = JSON.parse(response.data[0]).map((i, index) => ({
                  formId: i.formId,
                  value: JSON.parse(i.value),

                  hidden: i.hidden ? i.hidden : false,
                  showFrom: i.showFrom ? i.showFrom : null,
                  showUntil: i.showUntil ? i.showUntil : null,
                  restrictionGroups: i.restrictionGroups
                    ? JSON.parse(i.restrictionGroups)
                    : null,
                  created_at: i.created_at ? i.created_at : null,
                  updated_at: i.updated_at ? i.updated_at : null,

                  sortOrder: i.sortOrder != null ? i.sortOrder : index,
                  revisionCount: i.revisionCount != null ? i.revisionCount : 0,
                  ChangeUser: i.ChangeUser != null ? i.ChangeUser : ""
                }));
              }


              let fields = JSON.parse(responseValue.data.definition)
                .fields.filter((f) => f.isOverview);

              if (fields == null || fields.length == 0) {
                fields = JSON.parse(responseValue.data.definition).fields;
              }

              self.fields.push({
                key: "formId",
                title: "#",
                type: "actions",
              });
              fields.forEach(function (field) {
                field.key = "value." + field.key;
                field.label = field.title;
                field.type = field.type;
                self.fields.push(field);
              });

              self.fields.push({
                key: "created_at",
                label: "Erstellt am",
              });
              self.fields.push({
                key: "updated_at",
                label: "Zuletzt geändert",
              });


              self.fields.push({
                key: "ChangeUser",
                label: "Letzte Änderung durch",
              });
              self.fields.push({
                key: "revisionCount",
                title: "Revisionen",
                type: "revisionCount",
              });
              self.fields.push({
                key: "actions",
                title: "Aktionen",
                type: "actions",
              });

              self.items = list;

              $(document).ready(function () {
                setTimeout(function () {
                  $(".collectionTable" + self.currentTableKey).DataTable({
                    retrieve: true,
                    responsive:true,


                    language: {
                      url: "//cdn.datatables.net/plug-ins/2.0.8/i18n/de-DE.json",
                    },
                  });
                }, 300);
              });
            });
        });
    },

    openCrud() {
      let self = this;
      this.$root.$children[0].showBaseModal = true;

      this.$root.$children[0].baseDialogComponentName = "DynamicCrudDialog";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = {};
      this.$root.$children[0].baseDialogData.foreignKeyValue =
        self.defItem.collectionId;
      this.$root.$children[0].baseDialogData.fields = self.defItem.definition;
      this.$root.$children[0].baseDialogData.formioFormId = self.defItem.formFormId
      this.$root.$children[0].baseDialogData.saveFunction = function () {
        self.saveData(self.$root.$children[0].baseDialogData.item);
      };
      this.$root.$children[0].baseDialogData.item.OrgSaveFunction =
        this.$root.$children[0].baseDialogData.saveFunction;
      this.$root.$children[0].dialogTitle = "Datensatz hinzufügen"
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editCollectionItem(item) {
      let self = this;
      let index = 0;
      let editItem = {
        id: item.formId,
        value: item.value,
        fullItems: item.fullItems,
        hidden: item.hidden ? item.hidden : false,
        showFrom: item.showFrom ? item.showFrom : null,
        showUntil: item.showUntil ? item.showUntil : null,
        restrictionGroups: item.restrictionGroups
          ? JSON.parse(item.restrictionGroups)
          : null,
        created_at: item.created_at ? item.created_at : null,
        sortOrder: item.sortOrder != null ? item.sortOrder : index,
        formioFormId: item.formFormId
      };

      self.$root.$children[0].editForm(editItem, function () {
        self.initializeCollection();
      });
    },

    deleteCollectionItem(item) {
      let self = this;
      let editItem = { id: item.formId, value: item.value };

      self.$root.$children[0].deleteForm(editItem, function () {
        self.initializeCollection();
      });
    },

    copyCollectionItem(item) {
      let self = this;

      self.$root.$children[0].copyForm(item, function () {
        self.initializeCollection();
      });
    },

    deploy(item) {
      let self = this;
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseModalSize ="xl"
      this.$root.$children[0].baseDialogComponentName = "Deploy";
      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.item = item;
      this.$root.$children[0].baseDialogData.item.collectionId = 1;
      this.$root.$children[0].baseDialogData.item.formId = item.formId;
      this.$root.$children[0].baseDialogData.item.formName = item.value.formName;
      this.$root.$children[0].baseDialogData.item.value = JSON.stringify(item.value);
      this.$root.$children[0].baseDialogData.valueField = "formId";

      this.$root.$children[0].baseDialogData.textField = "formName";
      this.$root.$children[0].baseDialogData.table = "form";
      this.$root.$children[0].baseDialogData.crudTable = "form";
      this.$root.$children[0].baseDialogData.deploymentDone = function () {

        alert("Übertragung erfolgreich")
        self.initializeCollection();
      };

      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },
    saveData(item) {
      let self = this;
      item.collectionId = self.defItem.collectionId;
      item.value = JSON.stringify(item);

      if (
        item.formId != null &&
        item.formId != undefined &&
        item.formId != -1
      ) {
        axios
          .put(
            config.API_URL + "/api/form/" + item.formId,
            item
          )
          .then((response) => {
            item.formId = response.data.formId;
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeCollection();
          });
      } else {
        axios
          .post(config.API_URL + "/api/form/", item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            item.formId = response.data.formId;
            self.$root.$children[0].afterSaveFunctionAction(item);

            self.initializeCollection();
          });
      }
    },

    showRevisions(item) {
      let self = this;


      let query = {};
      query.query = "usf_RevisionForTable('form'," + item.formId + ")";
      axios
        .post(config.API_URL + "/api/dynamicQuery/", query)
        .then((response) => {
          self.$root.$children[0].showBaseModal = true;

          self.$root.$children[0].baseDialogComponentName = "JSONEditorDialog";
          self.$root.$children[0].baseDialogData = {};
          self.$root.$children[0].baseDialogData.canSave = false;


          let itemCopy = JSON.parse(response.data);

          itemCopy = itemCopy.map((e) => ({
            changeDate: e.created_at,
            changeUser: e.ChangeUser,
            old_value: JSON.parse(e.old_value),
            new_value: JSON.parse(e.new_value),
          }));

          self.$root.$children[0].baseDialogData.json = itemCopy;

          self.$root.$children[0].baseDialogData.Key = moment().format("X");
          self.showLoadingOverlay = false;
        });
      /*loadRevivsiosn*/

    },
  },
};
</script>
