<template>
  <div class="mt-3 mb-4" style="width: 100%">
    <b-row>
      <b-col cols="10">
        <b-btn @click="saveForm()">Speichern</b-btn>
      </b-col>
    </b-row>
    <PageBuilder
      :contentComponents.sync="contentBuild"
      :key="'mailBuilder' + $root.$children[0].viewComponentKey"
    ></PageBuilder>
  </div>
</template>

<script>
import config from "@/config/config.js";
import axios from "axios";
import moment from "moment";
import { Formio } from "formiojs";
export default {
  name: "MailBuilder",

  data: () => ({
    formData: null,
    baseFormData: null,
    mappedPath: null,
    formTitle: null,
    currentForm: {},
    displayType: "form",
    definitionName: "",
    collectionValue: {},
    contentBuild: [],
  }),
  mounted() {
    let self = this;
    if (this.$route.params.id != undefined) {
      self.definitionName = this.$route.params.identifier;
      axios
        .get(config.API_URL + "/api/CollectionItem/" + self.$route.params.id)
        .then((response) => {
          self.collectionValue = response.data;
          self.currentForm = JSON.parse(response.data.value);

          if (
            self.currentForm[self.definitionName] != null &&
            self.currentForm[self.definitionName] != undefined
          ) {
            self.contentBuild = self.currentForm[self.definitionName];
          } else {
            self.contentBuild = [];
          }
        });
    }
  },
  methods: {
    saveForm() {
      let self = this;

      self.collectionValue.value = JSON.stringify(self.currentForm);

      axios.put(
        config.API_URL + "/api/CollectionItem/" + self.$route.params.id,
        self.collectionValue,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },

    loadBuilder() {
      let self = this;

      let definition = "";
      if (typeof self.currentForm[self.definitionName] === "string") {
        definition = JSON.parse(self.currentForm[self.definitionName]);
      } else {
        definition = self.currentForm[self.definitionName];
      }
      Formio.setBaseUrl(config.API_URL + "/api/formManager");
      Formio.setProjectUrl(config.API_URL + "/api/formManager");
      Formio.builder(document.getElementById("builder"), definition).then(
        function (builder) {
          builder.on("saveComponent", function () {
            self.currentForm.definition = builder.schema;

            self.saveForm();
          });

          builder.on("addComponent", () => {
            self.currentForm[self.definitionName] = builder.schema;

            //  self.saveForm();
          });
          builder.on("removeComponent", () => {
            self.currentForm[self.definitionName] = builder.schema;

            //     self.saveForm();
          });
          builder.on("updateComponent", () => {
            self.currentForm[self.definitionName] = builder.schema;

            // self.saveForm();
          });
        }
      );
    },
  },

  watch: {
    displayType(val) {
      let self = this;
      let definition = "";
      if (typeof self.currentForm[self.definitionName] === "string") {
        definition = JSON.parse(self.currentForm[self.definitionName]);
      } else {
        definition = self.currentForm[self.definitionName];
      }
      definition.display = val;

      self.currentForm[self.definitionName] = definition;

      this.loadBuilder();
    },
  },
};
</script>









<style src="formiojs/dist/formio.full.css">
</style>

<style>
.v-application .formio-form ul {
  padding-left: 0px;
}
</style>



