var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{on:{"click":function($event){return _vm.$router.push('/cms/collections')}}},[_vm._v("Zurück")])],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"ozg-button",on:{"click":_vm.openCrud}},[_vm._v("Daten hinzufügen")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{attrs:{"id":"navigation-title"}},[_vm._v("Data-Collection ("+_vm._s(_vm.defItem.title)+")")])])],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{class:'collectionTable' + _vm.currentTableKey,attrs:{"fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [(data.field.type == 'Formio')?_c('span',[_c('b-button',{staticClass:"ozg-button small simple",attrs:{"href":'/cms/formBuilder/item/' +
                  data.item.collectionValueId +
                  '/' +
                  data.field.key.replace('value.', ''),"target":"_blank"}},[_vm._v(" Konfigurieren "),_c('small',[_c('b-icon-pencil')],1)])],1):(data.field.type == 'BPM')?_c('span',[_c('b-button',{staticClass:"ozg-button small simple",attrs:{"href":'/cms/bpmBuilder/item/' +
                  data.item.collectionValueId,"target":"_blank"}},[_vm._v(" BPMN "),_c('small',[_c('b-icon-pencil')],1)])],1):(data.field.type == 'revisionCount')?_c('span',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showRevisions(data.item)}}},[_vm._v(" "+_vm._s(data.item.revisionCount)+" ")])],1):(data.field.type == 'MailEditor')?_c('span',[_c('b-button',{staticClass:"ozg-button small simple",attrs:{"href":'/cms/mailBuilder/item/' +
                  data.item.collectionValueId +
                  '/' +
                  data.field.key.replace('value.', ''),"target":"_blank"}},[_vm._v(" Konfigurierenf "),_c('small',[_c('b-icon-pencil')],1)])],1):_c('span',[(data.field.type != undefined &&
                  data.field.type.includes('foreign')
                  )?_c('span',[(data != null && data != undefined && data.field.type == 'foreignMultiSelect' &&
                    data.value != null &&
                    data.value != undefined &&
                    data.value != ''
                    )?_c('span',[_vm._v(" "+_vm._s(data.value .map((e) => e[data.field.foreignText]) .join(", "))+" ")]):_vm._e(),(data != null && data != undefined && data != '' &&
                      data.field.type == 'foreignMultiSelectWithMax' &&
                      data.value != null &&
                      data.value != undefined &&
                      data.value != ''
                      )?_c('span',[_vm._v(" "+_vm._s(data.value .map((e) => e[data.field.foreignText]) .join(", "))+" ")]):_vm._e(),(data.field.type == 'foreignSingleSelect')?_c('span',[_vm._v(" "+_vm._s(data.item.value[data.field.foreignValue][ data.field.foreignText ])+" ")]):_c('span',[_vm._v(" "+_vm._s(data.value.text)+" ")])]):_c('span',[_vm._v(" "+_vm._s(data.value)+" ")])])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticStyle:{"width":"65px"}},[_c('b-icon-pen',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"margin-right":"10px","cursor":"pointer"},attrs:{"font-scale":"1","title":" bearbeiten"},on:{"click":function($event){return _vm.editCollectionItem(item)}}}),_c('b-icon-files',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"margin-right":"10px","cursor":"pointer"},attrs:{"font-scale":"1","title":" kopieren"},on:{"click":function($event){return _vm.copyCollectionItem(item)}}}),_c('b-icon-trash',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"font-scale":"1","title":" löschen"},on:{"click":function($event){return _vm.deleteCollectionItem(item)}}})],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }